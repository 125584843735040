import { defineStore } from 'pinia';
import { useCommonApi } from '../../../api';
import { ref, type Ref } from 'vue';
import type { Crop } from '../models/crop/crop.model';

export const useCropStore = defineStore('cropStore', () => {
  const cropApiService = useCommonApi().crop;
  const crops: Ref<Crop[]> = ref([]);

  const init = async () => {
    if (!crops.value.length) {
      await loadCrops();
    }
  };

  const loadCrops = async () => {
    const response = await cropApiService.listCrops();
    if (response.data) {
      crops.value = response.data;
    }
  };

  init();

  return {
    crops,
    loadCrops
  };
});
