import { FormValidator, type FormValidatorConfig, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';

export interface MinValidatorSettings extends FormValidatorSettings {
  min: number;
}

export type MinValidatorConfig = FormValidatorConfig<MinValidatorSettings>;

export class MinValidator extends FormValidator<MinValidatorSettings> {
  constructor() {
    super();
    this._name = 'min';
    this._settings = {
      min: 0,
      invalidMessage: 'form.validator.min.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any) {
    if (isEmpty(value)) {
      this.reset();
      return;
    }

    this._valid = this._settings.min <= value;

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage, 'error', {
        min: this._settings.min
      });
    }
  }
}
