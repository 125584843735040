import cloneDeepWith from 'lodash/cloneDeepWith';

const customCloner = (value: any): any => {
  if (value instanceof File) {
    return new File([value], value.name, { type: value.type });
  }
  // Not returning anything here will allow lodash to handle
  // other properties using its default deep clone behavior.
};

export const customDeepClone = (value: any): any => {
  return cloneDeepWith(value, customCloner);
};
