import type { NumberFilterSettings } from './number.filter';
import { NumberFilter } from './number.filter';

export type IntegerFilterSettings = NumberFilterSettings;

export class IntegerFilter extends NumberFilter<IntegerFilterSettings> {
  public override onFilter(value: any): any {
    if (this.isInvalidValue(value)) {
      console.warn(`[Integer filter] value must be a string or number. Type "${typeof value}" is not convertable to integer.`, value);
      return value;
    }

    value = parseInt(`${value}`, 10);
    return this.applyMinMax(value);
  }
}
