import { FormValidator, type FormValidatorConfig, type FormValidatorSettings } from '../models';

export type RequiredTrueValidatorSettings = FormValidatorSettings;

export type RequiredTrueValidatorConfig = FormValidatorConfig<RequiredTrueValidatorSettings>;

export class RequiredTrueValidator extends FormValidator<RequiredTrueValidatorSettings> {
  constructor() {
    super();
    this._name = 'requiredTrue';
    this._settings = {
      invalidMessage: 'form.validator.requiredTrue',
      needTranslate: true
    };
  }

  public override async onValidate(value: any) {
    this._valid = !!value;

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage);
    }
  }
}
