import { defineStore } from 'pinia';

export interface DataManagerState {
  itemsPerPage: number;
}

export const useDataManagerStore = defineStore('dataManager', {
  state: (): DataManagerState => ({
    itemsPerPage: 10
  })
});
