import type { FormFilterSettings } from '../models';
import { FormFilter } from '../models';

export interface NumberFilterSettings extends FormFilterSettings {
  min?: number;
  max?: number;
}

export class NumberFilter<TSettings extends NumberFilterSettings = NumberFilterSettings> extends FormFilter<TSettings> {
  public override onFilter(value: any): any {
    if (this.isInvalidValue(value)) {
      console.warn(`[Number filter] value must be a string or number. Type "${typeof value}" is not convertable to number.`, value);
      return value;
    }

    value = Number(`${value}`);
    return this.applyMinMax(value);
  }

  protected isInvalidValue(value: any) {
    if (value === null || value === undefined) return false;
    return typeof value === 'object' || Array.isArray(value);
  }

  protected applyMinMax(value: number) {
    const min = this._settings.min ?? null;
    if (min !== null) value = Math.max(value, min);

    const max = this._settings.max ?? null;
    if (max !== null) value = Math.min(value, max);

    if (value === null || value === undefined || isNaN(value)) {
      value = min ?? 0;
    }

    return value;
  }
}
