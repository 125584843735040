import { all as mergeAll } from 'deepmerge';
import vuetifyHu from 'vuetify/lib/locale/hu.mjs';
import vuetifyEn from 'vuetify/lib/locale/en.mjs';
import commonHu from './hu';
import commonEn from './en';

const hu = mergeAll([{ $vuetify: vuetifyHu }, commonHu]);
const en = mergeAll([{ $vuetify: vuetifyEn }, commonEn]);

export const commonI18n = { hu, en };
