import { FormValidator, type FormValidatorConfig, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';

const TAX_NUMBER_REGEXP = /^\d{8}-\d{1}-\d{2}$/;

export type TaxNumberValidatorSettings = FormValidatorSettings;
export type TaxNumberValidatorConfig = FormValidatorConfig<TaxNumberValidatorSettings>;

export class TaxNumberValidator extends FormValidator<TaxNumberValidatorSettings> {
  constructor() {
    super();
    this._name = 'tax-number';
    this._settings = {
      invalidMessage: 'form.validator.taxNumber.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any) {
    if (isEmpty(value)) {
      return;
    }

    this._valid = TAX_NUMBER_REGEXP.test(value);

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage);
    }
  }
}
