import { ref } from 'vue';

export const useIsLoading = () => {
  const isLoading = ref(false);

  const setIsLoading = (loading: boolean) => {
    isLoading.value = loading;
  };

  const handlePromiseLoading = async <T = any>(promise: Promise<T>) => {
    setIsLoading(true);

    const response = await promise.catch((err) => {
      setIsLoading(false);
      throw err;
    });

    setIsLoading(false);
    return response;
  };

  const handlePromisesLoading = async (...promises: Promise<any>[]) => {
    return await handlePromiseLoading(Promise.all(promises));
  };

  const handlePromisesLoadingSequenced = async (...promiseFunctions: (() => Promise<any>)[]) => {
    return await handlePromiseLoading(
      promiseFunctions.reduce((promise, next) => {
        return promise.then(next);
      }, Promise.resolve())
    );
  };

  return {
    isLoading,
    setIsLoading,
    handlePromiseLoading,
    handlePromisesLoading,
    handlePromisesLoadingSequenced
  };
};
