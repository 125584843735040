import type { ModalConfig } from '../';

export function createModalConfig<TConfig extends ModalConfig>(config: Partial<TConfig>): TConfig {
  return {
    fullscreen: false,
    persistent: false,
    scrollable: false,
    width: 'auto',
    contentHeight: 'auto',
    loading: false,
    translate: true,
    open: false,
    ...config
  } as TConfig;
}
