import type { AxiosInstance } from 'axios';
import type { ApiPlugin } from '../';
import * as Auth from '../../auth';
import { ref, type Ref } from 'vue';
import { isPromise } from '../../../utils/promise.utils';

export class RefreshTokenPlugin implements ApiPlugin {
  static refreshInProgress: Ref<Promise<boolean> | null> = ref(null);

  initialize(instance: AxiosInstance): void {
    instance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        return RefreshTokenPlugin.getRejected(err, instance);
      }
    );
  }

  static async getRejected(error, instance) {
    const config = error.originalError.config;
    const authStore = Auth.useAuthStore();
    const notAuthUri = !this.isAuthApiUrl(config.url);

    if (notAuthUri && error.code === 401) {
      if (!config._retry) {
        try {
          if (RefreshTokenPlugin.refreshInProgress.value && isPromise(RefreshTokenPlugin.refreshInProgress.value)) {
            const refreshResponse = await RefreshTokenPlugin.refreshInProgress;

            if (await refreshResponse.value) {
              return instance(config);
            }
          } else {
            config._retry = true;

            RefreshTokenPlugin.refreshInProgress.value = authStore.refreshAccessToken().finally(() => {
              RefreshTokenPlugin.refreshInProgress.value = null;
            });

            const refreshResponse = RefreshTokenPlugin.refreshInProgress;

            if (await refreshResponse.value) {
              return instance(config);
            } else {
              return this.handleAuthError(error);
            }
          }
        } catch (_error) {
          return this.handleAuthError(_error);
        }
      } else {
        return this.handleAuthError(error);
      }
    }

    return Promise.reject(error);
  }

  static isAuthApiUrl(url?: string) {
    return url?.startsWith(`/token`);
  }

  static async handleAuthError(err: any) {
    const authStore = Auth.useAuthStore();

    await authStore.logout(true);
    return Promise.reject(err);
  }
}
