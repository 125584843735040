import type { ProplantaStatus } from '../../proplanta';

export enum ParcelSource {
  VANTAGE_DRAW = 'vantageDraw',
  VANTAGE_FILE_UPLOAD = 'vantageFileUpload',
  AGRO_MAP = 'agroMap'
}

export enum ParcelStatus {
  EDITING = 'editing',
  WAITING_FOR_FINALIZATION = 'waitingForFinalization', //pending
  FINALIZED = 'finalized',
  NEED_YEARLY_DATA = 'needYearlyData'
}

export interface ParcelItem {
  id: string;
  name: string;
  area: number;
  source: ParcelSource;
  status: ParcelStatus;
  settlement: string;
  progress: number;
  proPlantaStatus: ProplantaStatus;
  agroMapOrdered: boolean;
  proPlantaProgress: number;
}

export interface ParcelFilter {
  offerId?: string;
  source?: string[];
  searchText?: string;
}
