<script lang="ts" setup>
import type { PropType } from 'vue';
import type { FormElement } from '../models';
import { useFormElement } from '../composables';
import { AxialInfoBlock } from './';
import { useModals } from '../../modal';

const props = defineProps({
  element: {
    type: Object as PropType<FormElement>,
    required: true
  },
  fieldAnimation: {
    type: [String, undefined] as PropType<string | undefined>
  },
  removeEnabled: {
    type: Boolean,
    default: false
  },
  removeAttributes: {
    type: Object,
    default: () => {}
  },
  removeNeedTranslate: {
    type: Boolean,
    default: false
  },
  removeNeedConfirm: {
    type: Boolean,
    default: false
  },
  removeConfirmTitle: {
    type: String,
    default: ''
  },
  removeConfirmText: {
    type: String,
    default: ''
  }
});

const {
  component,
  componentSettings,
  visible,
  hideGridSlotWhenInvisible,
  gridSettings,
  spacerSettings,
  infoBlock,
  density,
  removeFromParent,
  removable
} = useFormElement(props.element);

const modals = useModals();

const remove = () => {
  if (props.removeNeedConfirm) {
    modals.openConfirm({
      title: props.removeConfirmTitle,
      text: props.removeConfirmText,
      translate: props.removeNeedTranslate,
      onConfirm: () => removeFromParent()
    });
  } else {
    removeFromParent();
  }
};
</script>

<template>
  <v-col
    v-if="spacerSettings"
    v-bind="spacerSettings"></v-col>
  <v-col
    v-if="visible || !hideGridSlotWhenInvisible"
    v-bind="gridSettings">
    <transition :name="fieldAnimation">
      <div v-if="visible">
        <div
          v-if="removeEnabled && removable"
          class="axial-field__actions">
          <v-btn
            v-bind="removeAttributes"
            @click.prevent="remove" />
        </div>
        <component
          :is="component"
          :componentSettings="componentSettings"
          :element="element" />
        <axial-info-block
          v-if="infoBlock"
          :content="infoBlock"
          :density="density" />
      </div>
    </transition>
  </v-col>
</template>
