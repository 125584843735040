<script lang="ts" setup>
import type { PropType } from 'vue';
import { FormElement, useFormElement } from '../';

const props = defineProps({
  element: {
    type: Object as PropType<FormElement>,
    required: true
  }
});

const { labelComponent, label, hint, suffix, attributes, enabled } = useFormElement(props.element);
</script>

<template>
  <div
    class="v-input"
    data-testid="display-container">
    <label
      class="v-display-label"
      data-testid="display-label">
      <template v-if="labelComponent">
        <component
          :is="labelComponent"
          :label="label"
          :props="{}"
          :element="element" />
      </template>
      <template v-else>
        {{ label }}
      </template>
    </label>
    <div
      class="v-display-value"
      :class="{ 'text-disabled': !enabled }"
      data-testid="display-value">
      {{ element.value }}
      {{ suffix }}
    </div>
    <div
      v-if="!attributes.hideDetails"
      class="v-message">
      {{ hint }}
    </div>
  </div>
</template>
