export default {
  dataTable: {
    viewMode: {
      table: 'Table view',
      grid: 'Grid view'
    },
    sort: {
      sort: 'Sort',
      none: 'Default'
    },
    selectAll: 'Select all'
  },
  dataManager: {
    paginator: {
      itemsPerPage: 'Items per page'
    }
  },
  modal: {
    close: 'Close',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  validation: {
    required: 'The field is required!',
    companyNumber: 'Not valid company number format! (BS-CF-NNNNNN)',
    minChars: 'Minimum {min} character!',
    maxChars: 'Maximum {max} character!',
    email: 'Not valid email format!'
  },
  form: {
    input: {
      datePicker: {
        selectDate: 'Choose a date'
      },
      formArray: {
        confirmClearTitle: 'Clear all items',
        confirmClearText: 'Are you sure you want to clear all the elements?',
        confirmRemoveTitle: 'Remove item',
        confirmRemoveText: 'Are you sure you want to remove the element?'
      }
    }
  },
  login: {
    signIn: 'Sign in',
    email: 'E-mail',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password'
  },
  notification: {
    default: {
      success: 'The operation was successful',
      error: 'There was an error while processing your request'
    }
  },
  map: {
    calculationMode: {
      isolated: 'Isolated',
      merged: 'Merged'
    }
  }
};
