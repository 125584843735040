<script lang="ts" setup>
import type { PropType } from 'vue';
import { useSelectableFormElement } from '../composables';
import { SelectableFormElement } from '../models';
import { AxialFieldMessage } from './';

const props = defineProps({
  element: {
    type: Object as PropType<SelectableFormElement>,
    required: true
  },
  componentSettings: {
    type: Object as PropType<any>,
    required: false
  }
});

const {
  attributes,
  enabled,
  loading,
  value,
  fullName,
  messagesAsString,
  hasErrorMessages,
  hasWarningMessages,
  hasInfoMessages,
  onInput,
  onFocus,
  onBlur,
  labelComponent,
  prependComponent,
  appendComponent,
  items,
  itemAppendComponent,
  itemPrependComponent,
  label,
  hint,
  color,
  bgColor,
  density,
  variant,
  isColumn,
  isInline,
  itemLabelComponent,
  itemLabel,
  itemValue
} = useSelectableFormElement(props.element);
</script>

<template>
  <v-input
    :label="label"
    :hint="hint"
    :loading="loading"
    :density="density"
    :disabled="!enabled"
    :messages="messagesAsString"
    :class="{
      'v-input--error': hasErrorMessages,
      'v-input--warning': hasWarningMessages,
      'v-input--info': hasInfoMessages
    }">
    <template #default>
      <div class="flex flex-column">
        <v-label class="ms-2">
          <template v-if="labelComponent">
            <component
              :is="labelComponent"
              :label="label"
              :props="{}"
              :element="element" />
          </template>
          <template v-else>
            {{ label }}
          </template>
        </v-label>
        <div
          class="mt-2 ms-2 d-flex"
          :class="{
            'flex-column': isColumn,
            'flex-row': isInline
          }">
          <v-checkbox
            v-for="(item, i) in items"
            :key="i"
            v-model="value"
            v-bind="attributes"
            :name="fullName + '.' + item.value"
            :disabled="!enabled"
            :color="color"
            :bg-color="bgColor"
            :variant="variant"
            :density="density"
            :label="itemLabel(item)"
            :value="itemValue(item)"
            @focus="onFocus"
            @blur="onBlur"
            @input="onInput">
            <template
              v-if="itemLabelComponent"
              #label="{ label, props }">
              <component
                :is="itemLabelComponent"
                :label="label"
                :props="props"
                :element="element"
                :item="item"
                :item-index="i" />
            </template>
            <template
              v-if="itemPrependComponent"
              #prepend>
              <component
                :is="itemPrependComponent"
                :element="element"
                :item="item"
                :item-index="i" />
            </template>
            <template
              v-if="itemAppendComponent"
              #append>
              <component
                :is="itemAppendComponent"
                :element="element"
                :item="item"
                :item-index="i" />
            </template>
          </v-checkbox>
        </div>
      </div>
    </template>
    <template #message>
      <axial-field-message :element="element" />
    </template>
    <template
      v-if="prependComponent"
      #prepend>
      <component
        :is="prependComponent"
        :element="element" />
    </template>
    <template
      v-if="appendComponent"
      #append>
      <component
        :is="appendComponent"
        :element="element" />
    </template>
  </v-input>
</template>
