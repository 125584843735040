import { markRaw, ref } from 'vue';
import type { Component } from 'vue';
import type { ConfirmModalConfig, DialogModalConfig, Modal, ModalConfig, ModalProps } from '../';
import { ConfirmModal, createModalConfig, DialogModal } from '../';

class ModalService {
  private modals = ref<Modal[]>([]);

  /**
   * Opens a modal with the specified component and config
   *
   * @param component
   * @param config
   */
  public open<TConfig extends ModalConfig = ModalConfig>(component: Component<ModalProps<TConfig>>, config: Partial<TConfig>) {
    const availableIndex = this.findAvailable(component);

    if (availableIndex >= 0) {
      this.modals.value[availableIndex].config = { ...createModalConfig(config), open: true };
      return;
    }

    const modal = this.createModal(component, config);
    this.modals.value.push(modal);
  }

  /**
   * Opens a dialog modal
   *
   * @param config
   */
  public openDialog(config: Partial<DialogModalConfig>) {
    this.open(DialogModal, config);
  }

  /**
   * Opens a confirm modal
   *
   * @param config
   */
  public openConfirm(config: Partial<Omit<ConfirmModalConfig, 'persistent'>>) {
    this.open(ConfirmModal, { ...config, persistent: true });
  }

  /**
   * Returns all the instantiated modals
   */
  public getModals = () => this.modals;

  /**
   * Finds the first available modal instance's index
   * A modal is available and reusable if it is closed and the opening modal's type is the same
   *
   * @param component
   * @private
   */
  private findAvailable<TConfig extends ModalConfig = ModalConfig>(component: Component<ModalProps<TConfig>>) {
    const comp = markRaw(component);
    return this.modals.value.findIndex((m) => m.component === comp && !m.config.open);
  }

  /**
   * Creates a new modal instance with the specified configuration
   *
   * @param component
   * @param config
   * @private
   */
  private createModal<TConfig extends ModalConfig = ModalConfig>(component: Component<ModalProps<TConfig>>, config: Partial<TConfig>): Modal {
    return {
      component: markRaw(component),
      config: createModalConfig({ ...config, open: true })
    } as Modal;
  }
}

const modalService = new ModalService();

export const useModals = () => modalService;
