import type { Router } from 'vue-router';
import { useAuthStore } from '../stores/auth.store';
import type { AuthTokens } from '../models';
import { isAppName, isPromise } from '../../../utils';
import { getAdminHomeRouteFromRole } from '../utils';

export const createAuthGuard = (router: Router, loginRouteName: string, homeRouteName = 'home') => {
  router.beforeEach(async (to) => {
    const authStore = useAuthStore();
    const routeQuery = to.query;

    if (authStore.initializingPromise && isPromise(authStore.initializingPromise) && to.name !== loginRouteName) {
      await authStore.initializingPromise;
    }

    if (isAppName('admin') && authStore.personalData) {
      const homeRoute = router.getRoutes().find((route) => route.name === homeRouteName);
      if (homeRoute) {
        homeRoute.redirect = getAdminHomeRouteFromRole(authStore.personalData?.role || null);
      }
    }

    // apply impersonation tokens if needed
    if (routeQuery.impersonate && routeQuery.impersonate === 'true') {
      const tokens: AuthTokens = {
        access: (routeQuery.access || '') as string,
        refresh: (routeQuery.refresh || '') as string
      };

      await authStore.impersonate(tokens);
      return { name: homeRouteName };
    }

    if ('openFromOtherApp' in routeQuery) {
      const tokens: AuthTokens = {
        access: (routeQuery.access || '') as string,
        refresh: (routeQuery.refresh || '') as string
      };

      await authStore.openFromOtherApp(tokens);

      return { name: homeRouteName };
    }

    // check if authentication is required and user is logged in
    if (!to.meta.requiresAuth) return;

    if (to.name != loginRouteName && !authStore.loggedIn) {
      return { name: loginRouteName, query: { redirect: to.fullPath } };
    }

    // check permissions
    const routePermissions = to.meta.permission;
    if (!routePermissions || !routePermissions.length || authStore.hasPermission(routePermissions)) return;

    return { name: homeRouteName };
  });
};
