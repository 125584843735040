import { FormValidator, type FormValidatorConfig, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';

export type RequiredValidatorSettings = FormValidatorSettings;

export type RequiredValidatorConfig = FormValidatorConfig<RequiredValidatorSettings>;

export class RequiredValidator extends FormValidator<RequiredValidatorSettings> {
  constructor() {
    super();
    this._name = 'required';
    this._settings = {
      invalidMessage: 'form.validator.required.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any) {
    this._valid = !isEmpty(value);

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage);
    }
  }
}
