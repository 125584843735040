/**
 * Generates a random integer between a minimum and maximum bounds
 *
 * @param min Inclusive minimum value
 * @param max Inclusive maximum value
 */
export function randomInt(min: number, max: number): number {
  return Math.floor(Math.random() * max) + min;
}

/**
 * Restricts the given value between a minimum and a maximum bound.
 *
 * @param value Value
 * @param min   Minimum value
 * @param max   Maximum value
 */
export function clamp(value: number, min: number, max: number) {
  return Math.max(min, Math.min(max, value));
}

/**
 * Restricts the given value between 0 and 1.
 *
 * @param value Value
 */
export function clamp01(value: number) {
  return clamp(value, 0, 1);
}

/**
 * Converts the given value into a new range keeping the old range ratio.
 *
 * @param value   Value
 * @param inMin   Current range minimum value
 * @param inMax   Current range maximum value
 * @param outMin  New range minimum value
 * @param outMax  New range maximum value
 * @param clampIn Clamp the value into the current range before converting
 */
export function fit(value: number, inMin: number, inMax: number, outMin: number, outMax: number, clampIn = false) {
  value = clampIn ? clamp(value, inMin, inMax) : value;
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

/**
 * Converts the given value between 0 and 1 keeping the old range ratio.
 *
 * @param value   Value
 * @param inMin   Current range minimum value
 * @param inMax   Current range maximum value
 * @param clampIn Clamp the value into the current range before converting
 */
export function fit01(value: number, inMin: number, inMax: number, clampIn = false) {
  return fit(value, inMin, inMax, 0, 1, clampIn);
}

export const exactPrecision = (number: number, precision: number) => {
  return number.toPrecision(precision).replace(new RegExp('((\\d\\.*){' + precision + '}).*'), '$1');
};
