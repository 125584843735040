import { defineStore } from 'pinia';
import type { DataTableViewMode } from '../';

export interface DataTableState {
  viewMode: DataTableViewMode | null;
}

export const useDataTableStore = defineStore('dataTable', {
  state: (): DataTableState => ({
    viewMode: null
  }),
  getters: {},
  actions: {
    setViewMode(viewMode: DataTableViewMode) {
      this.$patch({ viewMode });
    }
  }
});
