<script setup lang="ts">
import { toRefs, ref } from 'vue';
import type { WelcomeModalProps } from '../../auth/models/welcome-modal.model';
import { DialogModal } from '.';
import { LoadingCircular } from '../../../components';
import { useLoadHtmlContent } from '../../html-content';

const props = defineProps<WelcomeModalProps>();
const { config } = toRefs(props);
const checked = ref(false);

const { htmlContent } = useLoadHtmlContent(config.value.text);

const onConfirm = (close: () => void) => {
  config.value.onConfirm?.call(this, checked.value);
  close();
};
</script>

<template>
  <dialog-modal
    :config="config"
    class="welcome-modal">
    <div
      v-if="htmlContent"
      v-html="htmlContent.html"></div>

    <template v-else>
      <loading-circular class="w-100"></loading-circular>
    </template>

    <template #actions="{ close }">
      <div class="welcome-modal__actions">
        <v-checkbox
          v-model="checked"
          color="primary"
          :label="$t('welcomeModal.dontShowAgain')"></v-checkbox>
        <v-btn
          :loading="!htmlContent"
          variant="elevated"
          class="ml-6 mr-2 px-4"
          color="primary"
          data-testid="greetingAcceptBtn"
          @click="onConfirm(close)">
          {{ $t('modal.ok') }}
        </v-btn>
      </div>
    </template>
  </dialog-modal>
</template>
