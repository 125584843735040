import type { App } from 'vue';

export const setErrorHandlers = (app: App) => {
  app.config.errorHandler = vueErrorHandler;
  window.onerror = windowErrorHandler;
};

const vueErrorHandler = (error: any, vm: any, info: any) => {
  handleError({
    type: 'vue',
    data: { error, vm, info }
  });
};

const windowErrorHandler = (message: any, source: any, lineno: any, colno: any, error: any) => {
  handleError({
    type: 'window',
    data: { message, source, line: lineno, column: colno, error }
  });
};

const handleError = (error: any) => {
  const debugMode = !import.meta.env.PROD;

  if (debugMode) {
    console.error(`[ERROR (${error.type})]`);
    console.error(error.data);
  }
};
