import { FormValidator, type FormValidatorContext, type FormValidatorConfig, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';
import { useCommonApi } from '../../api';
import { toRaw } from 'vue';
import { setApiValidationErrorMessages } from '../utils/validartor.utils';

const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export interface EmailValidatorSettings extends FormValidatorSettings {
  emailExistsMessage: 'api.user.emailExists';
  sendUserId: boolean;
}
export type EmailValidatorConfig = FormValidatorConfig<EmailValidatorSettings>;

export class EmailValidator extends FormValidator<EmailValidatorSettings> {
  validatorApiService = useCommonApi().validator;

  constructor() {
    super();
    this._name = 'email';
    this._settings = {
      emailExistsMessage: 'api.user.emailExists',
      invalidMessage: 'form.validator.email.invalidMessage',
      needTranslate: true,
      sendUserId: false
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value) || !context.element.enabled) {
      return;
    }

    this._valid = EMAIL_REGEXP.test(value);

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage);
      return;
    }

    let userId = undefined;

    if (this._settings.sendUserId === true) {
      const values = toRaw(context.element.parent?.value);
      userId = values.id;
    }

    try {
      await this.validatorApiService.getValidateEmail(value, userId);
      this._valid = true;
    } catch (error: any) {
      setApiValidationErrorMessages(error.data.data, this);
      this._valid = false;
    }
  }
}
