import { FormValidator, type FormValidatorConfig, type FormValidatorContext, type FormValidatorSettings } from '../models';

export type ChildrenValidValidatorSettings = FormValidatorSettings;

export type ChildrenValidValidatorConfig = FormValidatorConfig<ChildrenValidValidatorSettings>;

export class ChildrenValidValidator extends FormValidator<ChildrenValidValidatorSettings> {
  constructor() {
    super();
    this._name = 'childrenValid';
    this._settings = {
      invalidMessage: 'form.validator.childrenValid.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(_: any, context: FormValidatorContext) {
    this._valid = context.element.children.reduce((prev, next) => prev && (!next.enabled || !next.visible || next.validWithChildren), true);

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage);
    }
  }
}
