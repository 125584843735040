import { all as mergeAll } from 'deepmerge';
import { isEmpty } from '../utils';

export abstract class FormFilter<TSettings extends FormFilterSettings = FormFilterSettings> {
  protected _name = '';
  protected _settings: TSettings = {} as TSettings;
  protected _enabled = true;

  public filter(value: any): any {
    const allowEmpty = this.settings.allowEmpty !== false;
    const undefinedEmptyValue = this.settings.undefinedEmptyValue === true;

    if (allowEmpty && isEmpty(value)) {
      return undefinedEmptyValue ? undefined : null;
    }

    return this.onFilter(value);
  }

  public abstract onFilter(value: any): any;

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get settings(): TSettings {
    return this._settings;
  }

  public set settings(settings: Partial<TSettings>) {
    this._settings = mergeAll([this._settings, settings]) as TSettings;
  }

  public get enabled() {
    return this._enabled;
  }

  public set enabled(enabled: boolean) {
    this._enabled = enabled;
  }
}

export interface FormFilterSettings {
  allowEmpty?: boolean;
  undefinedEmptyValue?: boolean;
}

export interface FormFilterConfig<TSettings extends FormFilterSettings = FormFilterSettings> {
  filter: string;
  settings?: TSettings;
}

export type FilterClass = new () => FormFilter;
