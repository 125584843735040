import { FormValidator, type FormValidatorConfig, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';

export interface DateIsAfterValidatorSettings extends FormValidatorSettings {
  dateToCompare: Date;
  allowEqual: boolean;
}

export type DateIsAfterValidatorConfig = FormValidatorConfig<DateIsAfterValidatorSettings>;

export class DateIsAfterValidator extends FormValidator<DateIsAfterValidatorSettings> {
  constructor() {
    super();
    this._name = 'dateIsAfter';
    this._settings = {
      invalidMessage: 'form.validator.dateIsAfter.invalidMessage',
      needTranslate: true,
      dateToCompare: new Date(),
      allowEqual: true
    };
  }

  public override async onValidate(value: any) {
    if (isEmpty(value)) {
      this.reset();
      return;
    }

    const dateValue = new Date(value);
    this._valid = this.settings.allowEqual ? dateValue >= this.settings.dateToCompare : value > this.settings.dateToCompare;

    if (!this._valid) {
      this.addMessage(this.settings.invalidMessage, 'error', { dateToCompare: this.settings.dateToCompare.toLocaleDateString() });
    }
  }
}
