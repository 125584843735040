import { FormValidator, type FormValidatorConfig, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';

export interface MaxValidatorSettings extends FormValidatorSettings {
  max: number;
}

export type MaxValidatorConfig = FormValidatorConfig<MaxValidatorSettings>;

export class MaxValidator extends FormValidator<MaxValidatorSettings> {
  constructor() {
    super();
    this._name = 'max';
    this._settings = {
      max: 0,
      invalidMessage: 'form.validator.max.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any) {
    if (isEmpty(value)) {
      this.reset();
      return;
    }

    this._valid = this._settings.max >= value;

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage, 'error', { max: this._settings.max });
    }
  }
}
