<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import type { DialogModalProps } from '../';
import BaseModal from './BaseModal.vue';

const props = defineProps<DialogModalProps>();
const { config } = toRefs(props);
const isTranslate = computed(() => config.value.translate);
</script>

<template>
  <base-modal :config="config">
    <template #title>
      <slot
        name="title"
        :title="config.title">
        {{ isTranslate ? $t(config.title, config.translateParams) : config.title }}
      </slot>
    </template>
    <template #default>
      <slot :text="config.text">
        <p v-html="isTranslate ? $t(config.text, config.translateParams) : config.text"></p>
      </slot>
    </template>
    <template #actions="{ close }">
      <slot
        name="actions"
        :close="close">
        <v-btn @click="close">
          {{ $t('modal.close') }}
        </v-btn>
      </slot>
    </template>
  </base-modal>
</template>
