import { type Form, type FormElement, FormElementPlugin } from '../models';

export class RevalidateFormOnInputPlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'revalidateFormOnInput';
  }

  public override onInput(element: FormElement<any>) {
    (element.form as Form).validate({ validateChildren: true, skipPlugins: false, skipMessages: true }).then();
  }
}
