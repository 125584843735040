<script lang="ts" setup>
import type { PropType } from 'vue';
import { useFormArrayElement } from '../composables';
import { FormArrayElement } from '../models';
import { AxialField, AxialFieldMessage } from './';
import { useModals } from '../../modal';

const props = defineProps({
  element: {
    type: Object as PropType<FormArrayElement>,
    required: true
  },
  componentSettings: {
    type: Object as PropType<any>,
    required: false
  }
});

const {
  attributes,
  settings,
  sheetAttributes,
  labelComponent,
  prependComponent,
  appendComponent,
  children,
  hasErrorMessages,
  hasWarningMessages,
  hasInfoMessages,
  density,
  label,
  addEnabled,
  addAttributes,
  addBtnEnabled,
  removeEnabled,
  removeAttributes,
  clearEnabled,
  clearAttributes,
  clearBtnEnabled,
  confirmClear,
  confirmClearTitle,
  confirmClearText,
  confirmClearNeedTranslate,
  confirmRemove,
  confirmRemoveTitle,
  confirmRemoveText,
  confirmRemoveNeedTranslate,
  add,
  clear,
  pageCount,
  page
} = useFormArrayElement(props.element);

const modals = useModals();

const addChild = () => {
  add();
};

const clearChildren = () => {
  if (confirmClear) {
    modals.openConfirm({
      title: confirmClearTitle.value,
      text: confirmClearText.value,
      translate: confirmClearNeedTranslate.value,
      onConfirm: () => clear()
    });
  } else {
    clear();
  }
};
</script>

<template>
  <v-input
    :hideDetails="attributes.hideDetails"
    class="axial-form-array"
    :class="{
      'v-input--error': hasErrorMessages,
      'v-input--warning': hasWarningMessages,
      'v-input--info': hasInfoMessages
    }">
    <template
      v-if="prependComponent"
      #prepend>
      <component
        :is="prependComponent"
        :element="element" />
    </template>
    <template #default>
      <v-sheet
        v-bind="sheetAttributes"
        width="100%">
        <div class="axial-form-array__head">
          <div>
            <v-label v-if="label">
              <template v-if="labelComponent">
                <component
                  :is="labelComponent"
                  :label="label"
                  :props="{}"
                  :element="element" />
              </template>
              <template v-else>
                {{ label }}
              </template>
            </v-label>
            <div
              v-if="attributes.hideDetails !== true"
              class="v-input__details mb-5">
              <axial-field-message :element="element" />
            </div>
          </div>
          <div class="axial-form-array__actions">
            <v-btn
              v-if="addEnabled"
              v-bind="addAttributes"
              :disabled="!addBtnEnabled"
              @click.prevent="addChild" />
            <v-btn
              v-if="clearEnabled"
              v-bind="clearAttributes"
              :disabled="!clearBtnEnabled"
              @click.prevent="clearChildren" />
          </div>
        </div>
        <v-row
          class="axial-form-array__body"
          :dense="density === 'compact'">
          <axial-field
            v-for="(child, i) in children"
            :key="`${i} - ${child.name}`"
            :element="child"
            :remove-enabled="removeEnabled"
            :remove-attributes="removeAttributes"
            :remove-confirm-text="confirmRemoveText"
            :remove-confirm-title="confirmRemoveTitle"
            :remove-need-confirm="confirmRemove"
            :remove-need-translate="confirmRemoveNeedTranslate" />
        </v-row>

        <template v-if="settings.pageable">
          <v-pagination
            :length="pageCount"
            v-model="page"></v-pagination>
        </template>
      </v-sheet>
    </template>
    <template
      v-if="appendComponent"
      #append>
      <component
        :is="appendComponent"
        :element="element" />
    </template>
  </v-input>
</template>
