import { ApiModule } from '../../../api/services';
import type { Crop } from '../models/crop/crop.model';

export class CropApi extends ApiModule {
  protected endpoint = '/crop';

  public listCrops() {
    return this.api.get<Crop[]>(this.endpoint);
  }
}
