import { ApiModule } from '../../api/services';
import type { Credentials, AuthTokens, PasswordResetData, PersonalData, ChangePasswordData } from '../';

export class AuthApi extends ApiModule {
  protected authEndpoint = '/token';
  protected userEndpoint = '/user';

  public login(credentials: Credentials) {
    return this.api.post<AuthTokens, typeof credentials>(`${this.authEndpoint}`, credentials);
  }

  public refreshToken(refreshToken: string) {
    return this.api.post<AuthTokens, { refresh: string }>(`${this.authEndpoint}/refresh`, { refresh: refreshToken });
  }

  public forgotPassword(email: string) {
    return this.api.post<void, { email: string }>(`${this.userEndpoint}/forgot-password`, { email });
  }

  public setPassword(passwordResetData: PasswordResetData) {
    return this.api.post<void, PasswordResetData>(`${this.userEndpoint}/set-password`, passwordResetData);
  }

  public loadPersonalData() {
    return this.api.get<PersonalData>(`${this.userEndpoint}/me`);
  }

  public savePersonalData(personalData: PersonalData) {
    return this.api.put<PersonalData, PersonalData>(`${this.userEndpoint}/me`, personalData);
  }

  public saveDemoOfferVisibility(visible: boolean) {
    return this.api.patch<any, any>(`${this.userEndpoint}/demo-offer-visibility`, { visible });
  }

  public changePassword(changePasswordData: ChangePasswordData) {
    return this.api.post<AuthTokens, ChangePasswordData>(`${this.userEndpoint}/change-password`, changePasswordData);
  }

  public impersonate(id: string) {
    return this.api.post<any, { id: string }>(`${this.userEndpoint}/${id}/impersonate`);
  }

  public getNewToken() {
    return this.api.post<AuthTokens>(`${this.userEndpoint}/token`);
  }
}
