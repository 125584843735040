import type { NumberFilterSettings } from './number.filter';
import { NumberFilter } from './number.filter';

export type FloatFilterSettings = NumberFilterSettings;

export class FloatFilter extends NumberFilter<FloatFilterSettings> {
  public override onFilter(value: any): any {
    if (this.isInvalidValue(value)) {
      console.warn(`[Float filter] value must be a string or number. Type "${typeof value}" is not convertable to float.`, value);
      return value;
    }

    value = parseFloat(`${value}`);
    return this.applyMinMax(value);
  }
}
