<script lang="ts" setup>
import { toRefs, watch } from 'vue';
import type { ModalProps } from '../';

const props = defineProps<ModalProps>();
const { config } = toRefs(props);

watch(
  config,
  () => {
    if (!config.value.open) {
      config.value.onClose?.call(this);
    }
  },
  { deep: true }
);

const close = () => {
  config.value.open = false;
};

defineExpose({
  close
});
</script>

<template>
  <v-dialog
    v-model="config.open"
    :fullscreen="config.fullscreen"
    :persistent="config.persistent"
    :scrollable="config.scrollable"
    :width="config.width">
    <v-card
      class="modal"
      :class="config.classes"
      :color="config.color"
      :loading="config.loading">
      <v-card-title class="modal__title">
        <slot name="title" />
      </v-card-title>
      <v-card-text
        class="modal__content"
        :style="{ height: config.contentHeight }">
        <slot />
      </v-card-text>
      <v-card-actions
        v-if="$slots.actions"
        class="modal__actions">
        <slot
          name="actions"
          :close="close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
