import { toRaw } from 'vue';
import { FormValidator, type FormValidatorContext, type FormValidatorConfig, type FormValidatorSettings } from '../models';

export interface GreaterThanatorSettings extends FormValidatorSettings {
  otherFieldName: string;
  allowEqual: boolean;
}

export type GreaterThanValidatorConfig = FormValidatorConfig<GreaterThanatorSettings>;

export class GreaterThanValidator extends FormValidator<GreaterThanatorSettings> {
  constructor() {
    super();
    this._name = 'greaterThan';
    this._settings = {
      invalidMessage: 'form.validator.greaterThan.invalidMessage',
      needTranslate: true,
      otherFieldName: '',
      allowEqual: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    const values = toRaw(context.values);
    const otherValue = values[this.settings.otherFieldName];

    this._valid = this.settings.allowEqual ? value >= otherValue : value > otherValue;

    if (!this._valid) {
      this.addMessage(this.settings.invalidMessage);
    }
  }
}
