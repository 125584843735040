import type { NotificationConfig } from '../models';

export function createNotificationConfig(config: Partial<NotificationConfig> & Pick<NotificationConfig, 'text'>): NotificationConfig {
  return {
    timeout: 3000,
    color: 'default',
    needTranslate: true,
    translatePlaceholders: {},
    ...config
  } as NotificationConfig;
}
