<script lang="ts" setup>
import { type PropType } from 'vue';
import { useDatePicker } from '../composables';

const props = defineProps({
  modelValue: {
    type: [Date, String, Array] as PropType<Date | string | Date[] | string[] | undefined>
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  clearable: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    required: false
  },
  chipsColor: {
    type: [String, Boolean] as PropType<string | true>,
    default: undefined
  },
  closableChips: {
    type: Boolean,
    default: true
  },
  pickerIcon: {
    type: String,
    default: 'mdi-calendar'
  },
  format: {
    type: String,
    required: false
  },
  displayFormat: {
    type: String,
    required: false
  },
  weekStartDay: {
    type: Number as PropType<0 | 1 | 2 | 3 | 4 | 5 | 6>,
    default: 1
  },
  sort: {
    type: [String, false] as PropType<'asc' | 'desc' | false>,
    default: 'asc'
  },
  showAdjacentMonths: {
    type: Boolean,
    default: false
  },
  allowedDates: {
    type: Array as PropType<string[] | Date[]>,
    required: false
  },
  min: {
    type: [String, Object] as PropType<string | Date>,
    required: false
  },
  max: {
    type: [String, Object] as PropType<string | Date>,
    required: false
  },
  hideHeader: {
    type: Boolean,
    default: true
  },
  closeCalendarOnPick: {
    type: Boolean,
    default: false
  },
  calendarLocation: {
    type: String,
    default: 'bottom right'
  }
});

const emit = defineEmits(['update:modelValue', 'blur', 'clear', 'keydown', 'update:search', 'deleteItem']);

const {
  inputRef,
  isUpdatingInputValue,
  isUpdatingModelValue,
  calendarModel,
  inputModel,
  isCalendarOpen,
  chipColor,
  isActionsDisabled,
  isClearable,
  isChipsClosable,
  searchValue,
  isSearchValueUpdating,
  firstSearchValueUpdateFix,
  isSearchValueDirty,
  minDate,
  maxDate,
  datesAllowed,
  getFormattedReturnValue,
  createInputModelValue,
  createCalendarModelValue,
  removeItem
} = useDatePicker(props);

const emitModelUpdate = () => {
  emit('update:modelValue', getFormattedReturnValue());
};

const onDatePick = (value: any) => {
  inputModel.value = createInputModelValue(value);
  emitModelUpdate();

  if (!props.multiple && props.closeCalendarOnPick) {
    isCalendarOpen.value = false;
  }

  inputRef.value?.focus();
  inputRef.value?.blur();
};

const onInputModelUpdate = (value: any) => {
  if (props.multiple) {
    if (!Array.isArray(value) || isUpdatingInputValue.value) return;
    onInputValueUpdate(value);
  } else if (isCalendarOpen.value) {
    calendarModel.value = createCalendarModelValue(value);
  }
  isUpdatingModelValue.value = true;
};

const onBlur = (event: FocusEvent) => {
  if (!props.multiple && isSearchValueDirty.value) {
    onInputValueUpdate(inputModel.value);
    isSearchValueDirty.value = false;
  }

  emit('blur', event);
};

const onClear = (event: PointerEvent) => {
  inputModel.value = props.multiple ? [] : undefined;
  emitModelUpdate();
  emit('clear', event);
};

const onChipClose = (item: any) => {
  removeItem(item.value);
  emitModelUpdate();
  emit('deleteItem', item);
};

const onInputValueUpdate = (value: any) => {
  isUpdatingInputValue.value = true;
  isSearchValueUpdating.value = true;

  calendarModel.value = createCalendarModelValue(value);
  inputModel.value = createInputModelValue(calendarModel.value);
  emitModelUpdate();

  isUpdatingInputValue.value = false;
};

const onKeyDown = (event: KeyboardEvent) => {
  if (!props.multiple && event.key === 'Enter') {
    inputRef.value?.blur();
  }

  emit('keydown', event);
};

const onSearch = () => {
  if (firstSearchValueUpdateFix.value) {
    firstSearchValueUpdateFix.value = false;
    return;
  }

  if (isSearchValueUpdating.value) {
    isSearchValueUpdating.value = false;
    return;
  }

  isSearchValueUpdating.value = true;
  isSearchValueDirty.value = true;
  emit('update:search', searchValue);

  setTimeout(() => {
    isSearchValueUpdating.value = false;
  }, 1);
};
</script>

<template>
  <v-combobox
    ref="inputRef"
    v-model="inputModel"
    :disabled="disabled"
    :readonly="readonly"
    :multiple="multiple"
    :clearable="isClearable"
    :color="color"
    :closable-chips="isChipsClosable"
    @update:model-value="onInputModelUpdate"
    @click:clear="onClear"
    @update:search="onSearch"
    @close="onChipClose"
    @blur="onBlur"
    @keydown="onKeyDown">
    <template
      v-if="$slots.prepend"
      #prepend="args">
      <slot
        name="prepend"
        :args="args" />
    </template>
    <template
      v-if="$slots.append"
      #append="args">
      <slot
        name="append"
        :args="args" />
    </template>
    <template
      v-if="$slots.label"
      #label="args">
      <slot
        name="label"
        :args="args" />
    </template>
    <template
      v-if="$slots.message"
      #message="args">
      <slot
        name="message"
        :args="args" />
    </template>
    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner="args">
      <slot
        name="prepend-inner"
        :args="args" />
    </template>
    <template
      v-if="$attrs.chips"
      #chip="attrs">
      <slot
        name="chip"
        :attrs="attrs">
        <v-chip
          v-bind="attrs.props"
          :color="chipColor"
          @click:close.prevent.stop="onChipClose(attrs.item)" />
      </slot>
    </template>
    <template #append-inner>
      <v-icon
        :class="{ 'v-icon--clickable': !isActionsDisabled }"
        :icon="pickerIcon">
      </v-icon>
      <v-menu
        activator="parent"
        :location="calendarLocation as any"
        :disabled="isActionsDisabled"
        v-model="isCalendarOpen"
        :close-on-content-click="false">
        <v-date-picker
          v-model="calendarModel"
          :disabled="isActionsDisabled"
          :multiple="multiple"
          :color="color"
          :show-adjacent-months="showAdjacentMonths"
          :hide-header="hideHeader"
          :allowed-dates="datesAllowed"
          :min="minDate"
          :max="maxDate"
          @update:model-value="onDatePick" />
      </v-menu>
    </template>
  </v-combobox>
</template>
