import type { AxiosInstance } from 'axios';
import type { ApiPlugin } from '../';
import * as Auth from '../../auth';

export class AuthHeaderPlugin implements ApiPlugin {
  initialize(instance: AxiosInstance): void {
    instance.interceptors.request.use((config) => {
      return AuthHeaderPlugin.getFulfilled(config);
    });
  }

  static getFulfilled(config) {
    const authStore = Auth.useAuthStore();
    const needAuthToken = !this.isAuthApiUrl(config.url);
    if (needAuthToken) {
      if (authStore.accessToken) {
        config.headers['Authorization'] = 'Bearer ' + authStore.accessToken;
      } else {
        delete config.headers['Authorization'];
      }
    }

    return config;
  }

  static isAuthApiUrl(url?: string) {
    return url?.startsWith('/token');
  }
}
