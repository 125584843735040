import { type FormElement, FormElementPlugin } from '../models';

export class ValidateAfterInitPlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'validateAfterInit';
  }

  public override onInitialize(element: FormElement<any>): void {
    element.validate({ skipMessages: true });
  }
}
