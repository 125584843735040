import { toRaw, toValue, reactive } from 'vue';
import { FormElement, type FormElementState, FormStepElement } from './form-element.model';
import type { FormConfig, StepperAttributes, StepperFormConfig, StepperFormSettings } from './form-config.model';
import type { FormValidationOptions, FormValidatorResult } from './form-validator.model';
import { createFormStepperAttributes, createFormValidationOptions } from '../utils';
import type { Promise } from 'cypress/types/cy-bluebird';
import { customDeepClone } from '../../../utils';

export type FormState = FormElementState;
export class Form<TConfig extends FormConfig = FormConfig, TState extends FormState = FormState> extends FormElement<TConfig, TState> {
  public constructor(config: TConfig) {
    super(config, null);

    const superValidate = this.validate;

    this.validate = (validationOptions?: FormValidationOptions): Promise<FormValidatorResult> => {
      validationOptions = createFormValidationOptions({ validateChildren: true, ...validationOptions });
      return superValidate(validationOptions) as Promise<FormValidatorResult>;
    };
  }

  protected override buildComponent() {
    // nothing to do
  }

  protected override buildValidators() {
    super.buildValidators();
    this.addValidator({ validator: 'childrenValid' });
  }

  public submit = async (validationOptions?: FormValidationOptions) => {
    await this.validate(validationOptions);

    return this.generateSubmitData();
  };

  public getElementInHierarchy = (fullName: string) => {
    const elements = this.allChildren;

    return elements.find((element) => element.fullName === fullName) || null;
  };

  public generateSubmitData() {
    return {
      values: toRaw(toValue(this.cleanValues(this))),
      validation: {
        valid: this.valid,
        data: this.validationDataByFields
      }
    } as FormSubmitData;
  }

  protected cleanValues(element: FormElement<any>) {
    const value = customDeepClone(element.value);
    const childrenNames = this.children.map((child) => child.name);

    if (value !== null && value !== undefined) {
      if (typeof value === 'object') {
        Object.keys(value).forEach((name) => {
          if (!(name in childrenNames)) delete value[name];
        });
      } else if (Array.isArray(value)) {
        value.forEach((_, index) => {
          if (!(`${index}` in childrenNames)) delete value[`${index}`];
        });
      }
    }

    element.children.forEach((child) => {
      value[child.name] = this.cleanValues(child);
    });

    return value;
  }

  protected override get initialValue(): any {
    return {};
  }

  public override filter(): void {
    this.filterWithChildren();
  }

  public scrollToFirstInvalid = (options?: ScrollIntoViewOptions | undefined) => {
    const invalidChild = this.findFirstInvalidChild();

    if (invalidChild) {
      invalidChild.scrollTo(options);
    }
  };

  public override get valid() {
    return this.validWithChildren;
  }

  public override set valid(valid) {
    this.validWithChildren = valid;
  }

  public override get validWithChildren() {
    return this._children.reduce((prev, next) => prev && (!next.enabled || !next.visible || next.validWithChildren), true);
  }

  public override set validWithChildren(valid) {
    if (valid) this.resetValidator();

    this.children.forEach((child) => {
      child.validWithChildren = valid;
    });
  }

  public override get dirty() {
    return this.dirtyWithChildren;
  }

  public override set dirty(dirty) {
    this.dirtyWithChildren = dirty;
  }

  public override get value() {
    return this._state.value;
  }

  public override set value(value) {
    const cloned = customDeepClone(toRaw(toValue(value)));

    this._state.value = { ...this._state.value, ...cloned };
    this._plugins.forEach((plugin) => plugin.onValueChange(this, cloned));
  }
}

interface StepperFormState extends FormState {
  step: number;
}

export class StepperForm extends Form<StepperFormConfig, StepperFormState> {
  protected _stepperAttributes!: StepperAttributes;

  protected override buildState() {
    super.buildState();
    this._state.step = (this._settings as StepperFormSettings).defaultStep || 1;
  }

  public override generateSubmitData() {
    return {
      values: toRaw(toValue(this.cleanStepperValues())),
      validation: {
        valid: this.valid,
        data: this.validationDataByFields
      }
    } as FormSubmitData;
  }

  protected cleanStepperValues() {
    const value = customDeepClone(this.value);
    const children: FormElement<any>[] = [];

    this.children.forEach((child) => children.push(...child.children));
    const childrenNames = children.map((child) => child.name);

    if (value !== null && value !== undefined) {
      if (typeof value === 'object') {
        Object.keys(value).forEach((name) => {
          if (!(name in childrenNames)) delete value[name];
        });
      } else if (Array.isArray(value)) {
        value.forEach((_, index) => {
          if (!(`${index}` in childrenNames)) delete value[`${index}`];
        });
      }
    }

    children.forEach((child) => {
      value[child.name] = this.cleanValues(child);
    });

    return value;
  }

  protected override buildAttributes() {
    super.buildAttributes();
    this._stepperAttributes = reactive(createFormStepperAttributes(this._config.stepperAttributes || {}));
  }

  public getStepElement = (index: number) => {
    if (index < 0 || index >= this.children.length) return null;

    return this.children.at(index) as FormStepElement;
  };

  public get stepperAttributes() {
    return this._stepperAttributes;
  }

  public override get messagesByFields() {
    let result = {};

    result[this.fullName] = toRaw(toValue(this.messages));
    this.children.forEach((stepChild) => {
      stepChild.children.forEach((child) => (result = { ...result, ...toRaw(child.messagesByFields) }));
    });

    return result;
  }

  public override get validationDataByFields() {
    let result = {};

    result[this.fullName] = {
      valid: this.validWithChildren,
      messages: toRaw(toValue(this.messages))
    };

    this.children.forEach((stepChild) => {
      stepChild.children.forEach((child) => (result = { ...result, ...toRaw(child.validationDataByFields) }));
    });

    return result;
  }

  public get step() {
    return this._state.step;
  }

  public set step(step) {
    this._state.step = step;
  }
}

export interface FormSubmitData {
  values: any;
  validation: {
    valid: boolean | undefined;
    data: { [key: string]: FormValidatorResult };
  };
}
