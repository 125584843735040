import type { AxiosInstance } from 'axios';
import type { ApiPlugin } from '..';

export class LanguageHeaderPlugin implements ApiPlugin {
  initialize(instance: AxiosInstance): void {
    instance.interceptors.request.use((config) => {
      return LanguageHeaderPlugin.getFulfilled(config);
    });
  }

  static getFulfilled(config) {
    config.headers['Accept-Language'] = 'hu-HU';

    return config;
  }
}
