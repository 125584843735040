import { FormValidator, type FormValidatorConfig, type FormValidatorContext, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';
import { useCommonApi } from '../../api';
import { setApiValidationErrorMessages } from '../utils/validartor.utils';

export type PhoneNumberValidatorSettings = FormValidatorSettings;
export type PhoneNumberValidatorConfig = FormValidatorConfig<PhoneNumberValidatorSettings>;

export class PhoneNumberValidator extends FormValidator<PhoneNumberValidatorSettings> {
  validatorApiService = useCommonApi().validator;

  constructor() {
    super();
    this._name = 'phoneNumber';
    this._settings = {
      invalidMessage: 'form.validator.phoneNumber.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value) || !context.element.enabled) {
      return;
    }

    this._valid = true;

    try {
      await this.validatorApiService.getValidatePhoneNumber(value);
    } catch (error: any) {
      setApiValidationErrorMessages(error.data.data, this);
      this._valid = false;
    }
  }
}
