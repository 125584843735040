import type { FormFilterSettings } from '../models';
import { FormFilter } from '../models';

export type StringTrimFilterSettings = FormFilterSettings;

export class StringTrimFilter extends FormFilter<StringTrimFilterSettings> {
  public override onFilter(value: any): any {
    if (typeof value !== 'string') {
      console.warn(`[StringTrim filter] value must be a string. Type "${typeof value}" is not processable.`, value);
      return value;
    }

    return value.trim();
  }
}
