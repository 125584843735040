import type {
  FormElementAttributes,
  FormElementConfig,
  FormElementSettings,
  FormGroupConfig,
  FormGroupSheetAttributes,
  FormValidationOptions,
  SelectableFormElementAttributes,
  SelectableFormElementConfig,
  SelectableFormElementSettings,
  StepperAttributes
} from '../models';

export const createFormElementConfig = (partial: FormElementConfig): FormElementConfig => {
  return {
    ...partial,
    settings: createFormElementSettings(partial.settings || {}),
    attributes: createFormElementAttributes(partial.attributes || {}),
    children: partial.children || [],
    validators: partial.validators || [],
    plugins: partial.plugins || []
  };
};

export const createFormElementSettings = (partial: FormElementSettings): FormElementSettings => {
  const grid = partial.grid !== undefined ? (typeof partial.grid === 'number' ? { cols: partial.grid } : partial.grid) : { cols: 12 };

  return {
    ...partial,
    autoInitialize: partial.autoInitialize !== undefined ? partial.autoInitialize : true,
    needTranslate: partial.needTranslate !== undefined ? partial.needTranslate : false,
    needHintTranslate: partial.needHintTranslate !== undefined ? partial.needHintTranslate : false,
    grid: grid,
    hideGridSlotWhenInvisible: partial.hideGridSlotWhenInvisible || false
  };
};

export const createFormElementAttributes = (partial: FormElementAttributes): FormElementAttributes => {
  return {
    ...partial,
    persistentHint: partial.persistentHint || false,
    persistentCounter: partial.persistentCounter || false,
    persistentPlaceholder: partial.persistentPlaceholder || false,
    hideDetails: partial.hideDetails !== undefined ? partial.hideDetails : false,
    autofocus: partial.autofocus || false,
    clearable: partial.clearable || false,
    persistentClear: partial.persistentClear || false
  };
};

export const createMultiFormElementConfig = (partial: SelectableFormElementConfig): SelectableFormElementConfig => {
  return {
    ...partial,
    settings: createMultiFormElementSettings(partial.settings || {}),
    attributes: createMultiFormElementAttributes(partial.attributes || {}),
    children: partial.children || [],
    validators: partial.validators || [],
    plugins: partial.plugins || []
  };
};

export const createMultiFormElementSettings = (partial: SelectableFormElementSettings): SelectableFormElementSettings => {
  return {
    ...partial,
    ...createFormElementSettings(partial),
    items: partial.items || [],
    needItemTranslate: partial.needItemTranslate || false,
    direction: partial.direction || 'column'
  };
};

export const createMultiFormElementAttributes = (partial: SelectableFormElementAttributes): SelectableFormElementAttributes => {
  return {
    ...createFormElementAttributes(partial),
    ...partial,
    multiple: partial.multiple || false,
    itemTitle: partial.itemTitle || 'title',
    itemValue: partial.itemValue || 'value',
    variant: partial.variant
  };
};

export const createFormGroupConfig = (partial: FormGroupConfig): FormGroupConfig => {
  return {
    ...partial,
    settings: createFormElementSettings(partial.settings || {}),
    attributes: createFormElementAttributes(partial.attributes || {}),
    sheetAttributes: createFormGroupSheetAttributes(partial.sheetAttributes || {}),
    children: partial.children || [],
    validators: partial.validators || [],
    plugins: partial.plugins || []
  };
};

export const createFormGroupSheetAttributes = (partial: FormGroupSheetAttributes): FormGroupSheetAttributes => {
  return {
    ...partial,
    border: partial.border !== undefined ? partial.border : false,
    elevation: partial.elevation || 0,
    rounded: partial.rounded !== undefined ? partial.rounded : false
  };
};

export const createFormStepperAttributes = (partial: StepperAttributes): StepperAttributes => {
  return {
    altLabels: false,
    ...partial
  };
};

export const createFormValidationOptions = (partial: FormValidationOptions): FormValidationOptions => {
  return {
    validateDisabled: false,
    validateInvisible: false,
    validateChildren: false,
    skipMessages: false,
    skipPlugins: false,
    ...partial
  };
};
