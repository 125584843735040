import { defineStore } from 'pinia';
import { vuetifyBreakpointConfig } from '../../modules/vuetify/config/vuetify-breakpoint.config';
import { useBreakpoints } from '@vueuse/core';
import { onMounted, ref } from 'vue';

export const createSidenavStore = (drawerInitialValue = true, railInitialValue = false) =>
  defineStore('sideNavState', () => {
    const drawer = ref(drawerInitialValue);
    const rail = ref(railInitialValue);

    onMounted(() => {
      if (drawer.value) {
        const breakpoints = useBreakpoints(vuetifyBreakpointConfig);

        drawer.value = breakpoints.isGreater('md');
      }
    });

    const toggleNavbar = () => {
      rail.value = false;
      drawer.value = !drawer.value;
    };
    const toggleMiniSidenav = () => {
      drawer.value = true;
      rail.value = !rail.value;
    };

    return {
      drawer,
      rail,
      toggleNavbar,
      toggleMiniSidenav
    };
  });

export const useSidenavState = createSidenavStore(true, false);
