import { computed, onMounted, ref, watch } from 'vue';
import dateFormat from 'date-fns/format';

export const useDatePicker = (props: any) => {
  const inputRef = ref<any>(null);

  const isUpdatingInputValue = ref(false);

  const isUpdatingModelValue = ref(false);

  const calendarModel = ref<Date | Date[] | undefined>();

  const inputModel = ref<Date | string | Date[] | string[] | undefined>();

  const isCalendarOpen = ref(false);

  const chipColor = computed(() => (typeof props.chipsColor === 'boolean' ? props.color : props.chipsColor));

  const isActionsDisabled = computed(() => props.disabled || props.readonly);

  const isClearable = computed(() => props.clearable && !isActionsDisabled.value);

  const isChipsClosable = computed(() => props.closableChips && props.multiple && !isActionsDisabled.value);

  const allowedDates = computed(() =>
    props.allowedDates ? props.allowedDates.map((dateStr: any) => new Date(new Date(dateStr).setHours(0, 0, 0, 0))) : undefined
  );

  const min = computed(() => (props.min ? new Date(new Date(props.min).setHours(0, 0, 0, 0)) : undefined));

  const max = computed(() => (props.max ? new Date(new Date(props.max).setHours(23, 59, 59, 59)) : undefined));

  const searchValue = computed({
    get: () => inputRef.value?.search,
    set: (value) => {
      if (inputRef.value) {
        inputRef.value.search = value;
      }
    }
  });

  const isSearchValueUpdating = ref(false);

  const firstSearchValueUpdateFix = ref(!props.multiple && !!props.modelValue);

  const isSearchValueDirty = ref(false);

  const formatDate = (value: Date) => {
    try {
      const date = new Date(value);
      return props.format ? dateFormat(date, props.format, { weekStartsOn: props.weekStartDay }) : date;
    } catch {
      return value;
    }
  };

  const formatDisplayDate = (value: Date) => {
    try {
      const format = props.displayFormat || props.format;
      const date = new Date(value);

      return format ? dateFormat(date, format, { weekStartsOn: props.weekStartDay }) : date;
    } catch {
      return value;
    }
  };

  const sortDates = (dates: any[]) => {
    if (!props.sort || !dates.length) return;

    if (typeof dates[0] === 'string') {
      dates.sort();
    } else {
      dates.sort((a, b) => {
        return props.sort === 'asc' ? a - b : b - a;
      });
    }
  };

  const isDateAllowed = (date: Date) => {
    if (min.value) {
      if (date.getTime() < min.value.getTime()) return false;
    }

    if (max.value) {
      if (date.getTime() > max.value.getTime()) return false;
    }

    if (allowedDates.value) {
      return (allowedDates.value as Array<Date>).some(
        (allowedDate) =>
          allowedDate.getFullYear() === date.getFullYear() && allowedDate.getMonth() === date.getMonth() && allowedDate.getDate() === date.getDate()
      );
    }

    return true;
  };

  const filterAllowedDates = (dates: Date[]) => {
    return dates.filter((date) => isDateAllowed(date));
  };

  const createDates = (value: Date | Date[] | string | string[]) => {
    const dates: Date[] = [];

    if (props.multiple) {
      if (Array.isArray(value)) {
        value.forEach((val: Date | string) => {
          if (typeof val === 'string' && isNaN(Date.parse(val))) return;

          const date = new Date(val);
          date.setHours(0, 0, 0, 0);
          if (!dates.map((d) => d.getTime()).includes(date.getTime())) {
            dates.push(date);
          }
        });
      } else {
        if ((typeof value === 'string' && !isNaN(Date.parse(value))) || typeof value !== 'string') {
          const date = new Date(value);
          date.setHours(0, 0, 0, 0);
          dates.push(date);
        }
      }

      sortDates(dates);

      return filterAllowedDates(dates);
    }

    if (typeof value === 'string' && isNaN(Date.parse(value))) return undefined;
    const date = new Date(value as Date | string);
    date.setHours(0, 0, 0, 0);

    return isDateAllowed(date) ? date : undefined;
  };

  const createCalendarModelValue = (value: Date | Date[] | string | string[] | undefined) => {
    if (!value) return props.multiple ? [] : undefined;

    return createDates(value);
  };

  const createInputModelValue = (value: Date | Date[] | string | string[] | undefined) => {
    if (!value) return props.multiple ? [] : undefined;

    const dates: Date | Date[] | undefined = createDates(value);

    if (Array.isArray(dates)) {
      const result: any[] = [];
      dates.forEach((val: Date) => {
        result.push(formatDisplayDate(val));
      });

      return result;
    }

    return dates ? formatDisplayDate(dates as Date) : undefined;
  };

  const removeItem = (value: any) => {
    if (!props.multiple || !Array.isArray(inputModel.value)) return;

    inputModel.value = (inputModel.value as Array<any>).filter((val) => val != value);
    calendarModel.value = createCalendarModelValue(inputModel.value);
  };

  const getFormattedReturnValue = () => {
    const value = calendarModel.value;

    if (!value || (Array.isArray(value) && !value.length)) return props.multiple ? [] : undefined;

    if (props.multiple && Array.isArray(value)) {
      return value.map((date) => formatDate(date)).filter((date) => !!date);
    }

    return formatDate(value as Date);
  };

  onMounted(() => {
    calendarModel.value = createCalendarModelValue(props.modelValue);
    inputModel.value = createInputModelValue(props.modelValue);
  });

  watch(
    () => props.modelValue,
    () => {
      if (isUpdatingModelValue.value) {
        isUpdatingModelValue.value = false;
        return;
      }

      calendarModel.value = createCalendarModelValue(props.modelValue);
      inputModel.value = createInputModelValue(props.modelValue);
    },
    { deep: true }
  );

  return {
    inputRef,
    isUpdatingInputValue,
    isUpdatingModelValue,
    calendarModel,
    inputModel,
    isCalendarOpen,
    chipColor,
    isActionsDisabled,
    isClearable,
    isChipsClosable,
    searchValue,
    isSearchValueUpdating,
    firstSearchValueUpdateFix,
    isSearchValueDirty,
    minDate: min,
    maxDate: max,
    datesAllowed: allowedDates,
    getFormattedReturnValue,
    createInputModelValue,
    createCalendarModelValue,
    removeItem
  };
};
