import { VAutocomplete, VCheckbox, VFileInput, VRadio, VRangeSlider, VSelect, VSlider, VSwitch, VTextarea, VTextField } from 'vuetify/components';
import {
  CheckboxElement,
  FormArrayElement,
  FormElement,
  FormGroupElement,
  FormStepElement,
  SelectableFormElement,
  VuetifyFormElement,
  VuetifySelectableFormElement
} from '../models';
import {
  AxialAsyncAutoComplete,
  AxialCheckboxGroup,
  AxialDisplayField,
  AxialFormGroup,
  AxialFormArray,
  AxialRadioGroup,
  SelectableVuetifyField,
  VuetifyField,
  AxialCombobox,
  VuetifyDatePicker
} from '../components';
import {
  ChildrenValidValidator,
  CompanyNumberValidator,
  DateIsAfterValidator,
  EmailValidator,
  GreaterThanValidator,
  LengthValidator,
  PasswordValidator,
  RequiredTrueValidator,
  RequiredValidator,
  SameAsValidator,
  SmallerThanValidator,
  ArrayLengthValidator,
  PhoneNumberValidator,
  UniqueInStringListValidator
} from '../validators';
import { ProgressPlugin, RevalidateFormOnInputPlugin, ValidateAfterInitPlugin } from '../plugins';
import { formService } from '../services';
import { FloatFilter, IntegerFilter, NumberFilter, StringTrimFilter, EmptyFilter } from '../filters';
import { TaxNumberValidator } from '../validators/tax-number.validator';
import { MinValidator } from '../validators/min.validator';
import { MaxValidator } from '../validators/max.validator';

export const registerDefaultElements = () => {
  formService.registerFormElement('text', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VTextField }
  });
  formService.registerFormElement('textarea', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VTextarea }
  });
  formService.registerFormElement('checkbox', {
    class: CheckboxElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VCheckbox }
  });
  formService.registerFormElement('radio', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VRadio }
  });
  formService.registerFormElement('select', {
    class: VuetifySelectableFormElement,
    componentClass: SelectableVuetifyField,
    componentSettings: { vuetifyComponent: VSelect }
  });
  formService.registerFormElement('file', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VFileInput }
  });
  formService.registerFormElement('range', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VRangeSlider }
  });
  formService.registerFormElement('slider', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VSlider }
  });
  formService.registerFormElement('switch', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VSwitch }
  });
  formService.registerFormElement('combobox', {
    class: SelectableFormElement,
    componentClass: AxialCombobox
  });
  formService.registerFormElement('formGroup', {
    class: FormGroupElement,
    componentClass: AxialFormGroup
  });
  formService.registerFormElement('formArray', {
    class: FormArrayElement,
    componentClass: AxialFormArray
  });
  formService.registerFormElement('formStep', {
    class: FormStepElement,
    componentClass: AxialFormGroup
  });
  formService.registerFormElement('checkboxGroup', {
    class: SelectableFormElement,
    componentClass: AxialCheckboxGroup
  });
  formService.registerFormElement('radioGroup', {
    class: SelectableFormElement,
    componentClass: AxialRadioGroup
  });
  formService.registerFormElement('autocomplete', {
    class: VuetifySelectableFormElement,
    componentClass: SelectableVuetifyField,
    componentSettings: { vuetifyComponent: VAutocomplete }
  });
  formService.registerFormElement('asyncAutocomplete', {
    class: SelectableFormElement,
    componentClass: AxialAsyncAutoComplete
  });
  formService.registerFormElement('datepicker', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VuetifyDatePicker }
  });
  formService.registerFormElement('display', {
    class: FormElement,
    componentClass: AxialDisplayField
  });
};

export const registerDefaultFilters = () => {
  formService.registerFilter('number', NumberFilter);
  formService.registerFilter('integer', IntegerFilter);
  formService.registerFilter('float', FloatFilter);
  formService.registerFilter('stringTrim', StringTrimFilter);
  formService.registerFilter('empty', EmptyFilter);
};

export const registerDefaultValidators = () => {
  formService.registerValidator('required', RequiredValidator);
  formService.registerValidator('length', LengthValidator);
  formService.registerValidator('arrayLength', ArrayLengthValidator);
  formService.registerValidator('childrenValid', ChildrenValidValidator);
  formService.registerValidator('smallerThan', SmallerThanValidator);
  formService.registerValidator('companyNumber', CompanyNumberValidator);
  formService.registerValidator('email', EmailValidator);
  formService.registerValidator('greaterThan', GreaterThanValidator);
  formService.registerValidator('password', PasswordValidator);
  formService.registerValidator('sameAs', SameAsValidator);
  formService.registerValidator('requiredTrue', RequiredTrueValidator);
  formService.registerValidator('taxNumber', TaxNumberValidator);
  formService.registerValidator('phoneNumber', PhoneNumberValidator);
  formService.registerValidator('dateIsAfter', DateIsAfterValidator);
  formService.registerValidator('uniqueInStringList', UniqueInStringListValidator);
  formService.registerValidator('min', MinValidator);
  formService.registerValidator('max', MaxValidator);
};

export const registerDefaultPlugins = () => {
  formService.registerPlugin('progress', ProgressPlugin);
  formService.registerPlugin('validateAfterInit', ValidateAfterInitPlugin);
  formService.registerPlugin('revalidateFormOnInput', RevalidateFormOnInputPlugin);
};

export const registerDefaults = () => {
  registerDefaultElements();
  registerDefaultFilters();
  registerDefaultValidators();
  registerDefaultPlugins();
};
