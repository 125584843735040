import { toRaw } from 'vue';
import { FormValidator, type FormValidatorConfig, type FormValidatorContext, type FormValidatorSettings } from '../models';

export interface SmallerThanValidatorSettings extends FormValidatorSettings {
  otherFieldName: string;
  allowEqual: boolean;
}

export type SmallerThanValidValidatorConfig = FormValidatorConfig<SmallerThanValidatorSettings>;

export class SmallerThanValidator extends FormValidator<SmallerThanValidatorSettings> {
  constructor() {
    super();
    this._name = 'smallerThan';
    this._settings = {
      invalidMessage: 'form.validator.smallerThan.invalidMessage',
      needTranslate: true,
      otherFieldName: '',
      allowEqual: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    const values = toRaw(context.values);
    const otherValue = values[this.settings.otherFieldName];

    this._valid = this._settings.allowEqual ? value <= otherValue : value < otherValue;

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage);
    }
  }
}
