import { Fill, Stroke, Style } from 'ol/style';
import { ParcelSource, type ParcelItem, ParcelStatus } from '../models';
import { ProplantaStatus } from '../../proplanta';

const maxParcelsAreaTolerancePercent = 1.05;

const minParcelsAreaTolerancePercent = 0.95;

export const RED_POLYGON_STYLE = new Style({ fill: new Fill({ color: 'rgba(255, 0, 0, 0.5)' }), stroke: new Stroke({ color: 'red' }) });
export const YELLOW_POLYGON_STYLE = new Style({ fill: new Fill({ color: 'rgba(255, 165, 0, 0.5)' }), stroke: new Stroke({ color: 'orange' }) });
export const GREEN_POLYGON_STYLE = new Style({ fill: new Fill({ color: 'rgba(0, 140, 0, 0.25)' }), stroke: new Stroke({ color: 'green' }) });

const parcelStateColorMap: Record<ParcelStatus, string> = {
  [ParcelStatus.EDITING]: 'blue',
  [ParcelStatus.FINALIZED]: 'green',
  [ParcelStatus.NEED_YEARLY_DATA]: 'blue',
  [ParcelStatus.WAITING_FOR_FINALIZATION]: 'blue'
};

const parcelStateTitleMap: Record<ParcelStatus, string> = {
  [ParcelStatus.EDITING]: 'parcel.status.editing',
  [ParcelStatus.FINALIZED]: 'parcel.status.finalized',
  [ParcelStatus.NEED_YEARLY_DATA]: 'parcel.status.NEED_YEARLY_DATA',
  [ParcelStatus.WAITING_FOR_FINALIZATION]: 'parcel.status.waiting_for_finalization'
};

const parcelSourceMap: Record<ParcelSource, string> = {
  [ParcelSource.AGRO_MAP]: 'parcel.source.agro_map',
  [ParcelSource.VANTAGE_DRAW]: 'parcel.source.draw',
  [ParcelSource.VANTAGE_FILE_UPLOAD]: 'parcel.source.file'
};

const proPlantaStatusColorMap: Record<ProplantaStatus, string> = {
  [ProplantaStatus.EDITING]: 'blue',
  [ProplantaStatus.FINALIZED]: 'green',
  [ProplantaStatus.NEED_YEARLY_DATA]: 'blue',
  [ProplantaStatus.WAITING_FOR_FINALIZATION]: 'blue',
  [ProplantaStatus.PARCEL_NOT_FINALIZED]: 'grey'
};

export function getParcelSourceText(source: ParcelSource): string {
  return parcelSourceMap[source];
}

export function needHighlightingBasedOnStatus(status: ParcelStatus): boolean {
  return status === ParcelStatus.NEED_YEARLY_DATA;
}

export function getParcelStateColor(status: ParcelStatus): string {
  return parcelStateColorMap[status];
}

export function getProPlantaStatusColor(status: ProplantaStatus): string {
  return proPlantaStatusColorMap[status];
}

export function getParcelStateTitle(status: ParcelStatus): string {
  return parcelStateTitleMap[status];
}

export function getProplantaStateTitle(status: ProplantaStatus): string {
  return parcelStateTitleMap[status];
}

export function needPercentBasedOnStatus(status: ParcelStatus): boolean {
  return status !== ParcelStatus.FINALIZED;
}

export function needPercentBasedOnProplantaStatus(status: ProplantaStatus): boolean {
  return status !== ProplantaStatus.FINALIZED;
}

export function parcelIsDeletable(parcelItem: ParcelItem): boolean {
  return (
    [ParcelStatus.EDITING, ParcelStatus.WAITING_FOR_FINALIZATION].includes(parcelItem.status) &&
    (!parcelItem.agroMapOrdered || parcelItem.source !== ParcelSource.AGRO_MAP)
  );
}

export function parcelIsMovable(parcelItem: ParcelItem): boolean {
  return (
    [ParcelStatus.EDITING, ParcelStatus.WAITING_FOR_FINALIZATION].includes(parcelItem.status) &&
    (!parcelItem.agroMapOrdered || parcelItem.source !== ParcelSource.AGRO_MAP)
  );
}

export function parcelIsFinalized(status: ParcelStatus): boolean {
  return status === ParcelStatus.FINALIZED;
}

export function getParcelStateFilterOptions() {
  return [ParcelStatus.EDITING, ParcelStatus.FINALIZED, ParcelStatus.WAITING_FOR_FINALIZATION, ParcelStatus.NEED_YEARLY_DATA].map((state) => ({
    label: parcelStateTitleMap[state],
    value: state
  }));
}

export function parcelsAreaTooLarge(uploadedArea: number, requiredArea: number): boolean {
  return uploadedArea / requiredArea > maxParcelsAreaTolerancePercent;
}

export function parcelsAreaTooSmall(uploadedArea: number, requiredArea: number): boolean {
  return uploadedArea / requiredArea < minParcelsAreaTolerancePercent;
}
