import { reactive } from 'vue';
import { type FormElement, FormElementPlugin } from '../models';

export interface ProgressPluginState {
  progress: number;
}

export class ProgressPlugin extends FormElementPlugin {
  protected _state: ProgressPluginState = reactive({
    progress: 0
  });

  public constructor() {
    super();
    this._name = 'progress';
  }

  public override onInput(element: FormElement<any>): void {
    this.calculate(element);
  }

  public override onFocus(element: FormElement<any>, focused: boolean) {
    if (!focused) this.calculate(element);
  }

  public override onValidate(element: FormElement<any>) {
    this.calculate(element);
  }

  public override async onHierarchyChange(element: FormElement<any>) {
    await element.validate({ skipMessages: true });
  }

  public calculate = (element: FormElement<any>) => {
    const children = element.allChildren.filter((child) => {
      const hasRequired = child.validatorChain.hasValidator('required');
      return hasRequired && child.visible;
    });

    const childrenCount = children.length;

    if (!childrenCount) {
      this._state.progress = 100;
      return;
    }

    const valid = children.filter((c) => c.validWithChildren).length;
    this._state.progress = Math.round((valid / childrenCount) * 100);
  };

  public reset = () => {
    this._state.progress = 0;
  };

  public get progress() {
    return this._state.progress;
  }
}
