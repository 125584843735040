<script lang="ts" setup>
import { computed } from 'vue';
import { useModals } from '../';

const modalService = useModals();
const modals = computed(() => modalService.getModals().value);
</script>

<template>
  <component
    v-for="modal in modals"
    :key="modal.config"
    :is="modal.component"
    :config="modal.config" />
</template>
