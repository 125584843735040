import { FormValidator, type FormValidatorConfig, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';

export interface PasswordValidatorSettings extends FormValidatorSettings {
  requireLowerCase?: boolean;
  requireUpperCase?: boolean;
  requireNumber?: boolean;
  requireSpecialCharacter?: boolean;
}

export type PasswordValidatorConfig = FormValidatorConfig<PasswordValidatorSettings>;

export class PasswordValidator extends FormValidator<PasswordValidatorSettings> {
  constructor() {
    super();
    this._name = 'password';
    this._settings = {
      invalidMessage: 'auth.validation.passwordFormat',
      needTranslate: true,
      requireLowerCase: true,
      requireUpperCase: true,
      requireNumber: true,
      requireSpecialCharacter: true
    };
  }

  public override async onValidate(value: any) {
    if (isEmpty(value)) {
      this.reset();
      return;
    }

    const passRegExp = this.generateRegExp();

    this._valid = passRegExp.test(value);

    if (!this._valid) {
      this.addMessage(this.settings.invalidMessage);
    }
  }

  private generateRegExp = (): RegExp => {
    let regExp = '';

    if (this._settings.requireLowerCase) {
      regExp += '(?=.*[a-záéíóöőúüűôõûũ])';
    }

    if (this._settings.requireUpperCase) {
      regExp += '(?=.*[A-ZÁÉÍÓÖŐÚÜŰÔÕÛŨ])';
    }

    if (this._settings.requireNumber) {
      regExp += '(?=.*\\d)';
    }

    if (this.settings.requireSpecialCharacter) {
      regExp += '(?=.*[!@#$%^&*()_+{}\\[\\]:;<>,.?~\\-])';
    }

    return new RegExp(regExp, 'u');
  };
}
