import { registerDefaults } from './utils/form-defaults.utils';

export * from './components';
export * from './composables';
export * from './models';
export * from './plugins';
export * from './services';
export * from './utils';
export * from './validators';
export * from './filters';

registerDefaults();
