import { FormValidator, type FormValidatorConfig, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';

export interface LengthValidatorSettings extends FormValidatorSettings {
  min?: number;
  max?: number;
  inclusive?: boolean;
  invalidMessageMin?: string;
  invalidMessageMax?: string;
}

export type LengthValidatorConfig = FormValidatorConfig<LengthValidatorSettings>;

export class LengthValidator extends FormValidator<LengthValidatorSettings> {
  constructor() {
    super();
    this._name = 'length';
    this._settings = {
      invalidMessage: 'form.validator.length.invalidMessage',
      invalidMessageMin: 'form.validator.length.invalidMessageMin',
      invalidMessageMax: 'form.validator.length.invalidMessageMax',
      needTranslate: true
    };
  }

  public override async onValidate(value: any) {
    if (isEmpty(value)) {
      this.reset();
      return;
    }

    const min = Math.max(this._settings.min || 0, 0);
    const max = this._settings.max !== undefined ? Math.max(this._settings.max || 0, min) : undefined;
    const inclusive = this._settings.inclusive || true;

    this._valid = this.checkValid(value, inclusive, min, max);

    if (!this._valid) {
      this.setMessage(min, max);
    }
  }

  protected valueLength = (value: any) => {
    let length;

    if (typeof value === 'undefined' || value === null) {
      length = 0;
    } else if (typeof value === 'string' || Array.isArray(value)) {
      length = value.length;
    } else if (typeof value === 'number') {
      length = `${value}`.length;
    } else if (typeof value === 'object') {
      length = Object.keys(value).length;
    } else {
      console.warn(
        `[Length Validator] Value must be a type of "string", "number" or "object". Provided value is "${typeof value}" and will be marked as valid.`
      );
      length = -1;
    }

    return length;
  };

  protected checkValid = (value: any, inclusive: boolean, min: number, max: number | undefined) => {
    const length = this.valueLength(value);
    let valid = true;

    if (length >= 0) {
      valid = inclusive ? length >= min && (max !== undefined ? length <= max : true) : length > min && (max !== undefined ? length < max : true);
    }

    return valid;
  };

  protected setMessage = (min: number, max: number | undefined) => {
    let message;
    let placeholders;

    if (min > 0 && max !== undefined) {
      message = this._settings.invalidMessage;
      placeholders = { min, max };
    } else if (min > 0) {
      message = this._settings.invalidMessageMin;
      placeholders = { min };
    } else {
      message = this._settings.invalidMessageMax;
      placeholders = { max };
    }

    this.addMessage(message, 'error', placeholders);
  };
}
