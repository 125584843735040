import { onMounted, ref, type Ref } from 'vue';
import { useCommonApi } from '../../api';
import type { HtmlContent } from '../model';

// TODO: handle language change
export const useLoadHtmlContent = (key: string) => {
  const htmlContentApi = useCommonApi().htmlContent;

  const htmlContent: Ref<HtmlContent | undefined> = ref(undefined);

  onMounted(() => {
    loadHtmlContent();
  });

  const loadHtmlContent = async () => {
    const response = await htmlContentApi.loadContent(key, 'hu').catch(() => {
      return { data: { html: '' } };
    });

    htmlContent.value = response.data;
  };

  return {
    htmlContent
  };
};
