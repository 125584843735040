<script lang="ts" setup>
import type { PropType, Ref } from 'vue';
import type { VuetifyFieldComponentSettings } from '../models';
import { FormElement } from '../models';
import { useFormElement } from '../composables';
import { AxialFieldMessage } from './';

const props = defineProps({
  element: {
    type: Object as PropType<FormElement>,
    required: true
  },
  componentSettings: {
    type: Object as PropType<VuetifyFieldComponentSettings>,
    required: true
  }
});

const {
  attributes,
  enabled,
  loading,
  readonly,
  value,
  fullName,
  messagesAsString,
  hasErrorMessages,
  hasWarningMessages,
  hasInfoMessages,
  onInput,
  onFocus,
  onBlur,
  labelComponent,
  prependComponent,
  prependInnerComponent,
  appendComponent,
  appendInnerComponent,
  color,
  bgColor,
  density,
  variant,
  label,
  suffix,
  maskValue
} = useFormElement(props.element);

const onSearch = (searchValue: Ref<string>) => {
  searchValue.value = maskValue(searchValue.value);
};
</script>

<template>
  <component
    :is="componentSettings.vuetifyComponent"
    v-bind="attributes"
    v-model="value"
    :class="{
      'v-input--error': hasErrorMessages,
      'v-input--warning': hasWarningMessages,
      'v-input--info': hasInfoMessages
    }"
    :name="fullName"
    :label="label"
    :suffix="suffix"
    :color="color"
    :bg-color="bgColor"
    :density="density"
    :variant="variant"
    :disabled="!enabled"
    :readonly="readonly"
    :messages="messagesAsString"
    :loading="loading"
    @focus="onFocus"
    @blur="onBlur"
    @input="onInput"
    @update:search="onSearch">
    <template #message>
      <axial-field-message :element="element" />
    </template>
    <template
      v-if="labelComponent"
      #label="{ label, props }">
      <component
        :is="labelComponent"
        :label="label"
        :props="props"
        :element="element" />
    </template>
    <template
      v-if="prependComponent"
      #prepend>
      <component
        :is="prependComponent"
        :element="element" />
    </template>
    <template
      v-if="prependInnerComponent"
      #prepend-inner>
      <component
        :is="prependInnerComponent"
        :element="element" />
    </template>
    <template
      v-if="appendComponent"
      #append>
      <component
        :is="appendComponent"
        :element="element" />
    </template>
    <template
      v-if="appendInnerComponent"
      #append-inner>
      <component
        :is="appendInnerComponent"
        :element="element" />
    </template>
  </component>
</template>
