import { type Component, markRaw, reactive } from 'vue';
import { toRaw, toValue } from 'vue';
import { debounce, type DebounceInstance } from 'vue-debounce';
import type {
  CheckboxAttributes,
  CheckboxConfig,
  FormArrayConfig,
  FormElementAttributes,
  FormElementComponentClassDefinition,
  FormElementConfig,
  FormElementPluginConfig,
  FormElementSettings,
  FormFilterConfig,
  FormGroupConfig,
  FormGroupSheetAttributes,
  FormStepConfig,
  FormValidationOptions,
  FormValidatorConfig,
  FormValidatorContext,
  FormValidatorMessage,
  FormValidatorResult,
  SelectableFormElementAttributes,
  SelectableFormElementConfig,
  SelectableFormElementSettings,
  VuetifyFieldComponentSettings
} from './';
import { Form, FormElementPlugin, FormFilter, FormValidator, FormValidatorChain } from './';
import { formService } from '../services';
import {
  createFormElementAttributes,
  createFormElementConfig,
  createFormElementSettings,
  createFormGroupConfig,
  createFormGroupSheetAttributes,
  createFormValidationOptions,
  createMultiFormElementConfig
} from '../utils';
import { createPipe } from 'imask';
import { customDeepClone, isPromise } from '../../../utils';
import { cloneDeep } from 'lodash';

export interface FormElementState {
  initialized: boolean;
  enabled: boolean;
  focused: boolean;
  visible: boolean;
  readonly: boolean;
  loading: boolean;
  dirty: boolean;
  value: any;
  valid: undefined | boolean;
  messages: FormValidatorMessage[];
  removable: boolean;
  needsReRender: boolean;
  removeFromResult: boolean;
}

export class FormElement<TConfig extends FormElementConfig = FormElementConfig, TState extends FormElementState = FormElementState> {
  protected _config!: TConfig;
  protected _component: Component | null = null;
  protected _componentSettings: any | null = null;
  protected _name!: string;
  protected _settings!: FormElementSettings;
  protected _attributes!: FormElementAttributes;
  protected _parent: FormElement<any> | null = null;
  protected _children!: FormElement<any>[];
  protected _filters!: FormFilter<any>[];
  protected _validatorChain!: FormValidatorChain;
  protected _currentValidation?: Promise<FormValidatorResult>;
  protected _state!: TState;
  protected _plugins = new Map<string, FormElementPlugin>();
  protected _positionInParent = 0;
  protected _mask: CallableFunction | undefined;
  protected _onInputDebounce!: DebounceInstance<[InputEvent]>;
  protected _beforeValueChange?: (element: FormElement, value: any) => boolean | Promise<boolean>;
  protected _afterValueChange?: (element: FormElement, value: any) => void;

  public constructor(config: TConfig, parent: FormElement<any> | null = null, positionInParent?: number) {
    this._config = config;
    this._parent = parent;
    this._positionInParent = positionInParent !== undefined ? positionInParent : parent?.children.length || 0;
  }

  public build(config?: TConfig) {
    this.buildConfig(config);
    this.buildComponent();
    this.buildName();
    this.buildSettings();
    this.buildAttributes();
    this.buildFilters();
    this.buildValidators();
    this.buildPlugins();
    this.buildState();
    this.buildChildren();
  }

  protected buildConfig(config?: TConfig) {
    this._config = createFormElementConfig(config || this._config) as TConfig;
  }

  protected buildComponent() {
    const componentName = this._config.component || '';
    const componentDefinition = formService.getFormElement(componentName);

    if (componentDefinition) {
      this._component = markRaw(componentDefinition.componentClass);
      this._componentSettings = componentDefinition.componentSettings || null;
    }
  }

  protected buildName() {
    this._name = this._config.name;
  }

  protected buildSettings() {
    this._settings = reactive(createFormElementSettings(this._config.settings || {}));

    if (this._settings.mask) {
      const mask = 'config' in this._settings.mask ? this._settings.mask.config : this._settings.mask;
      this._mask = createPipe(mask);
    }

    if (this._settings.beforeValueChange !== undefined) {
      this._beforeValueChange = this._settings.beforeValueChange;
    }

    if (this._settings.afterValueChange !== undefined) {
      this._afterValueChange = this._settings.afterValueChange;
    }
  }

  protected buildAttributes() {
    this._attributes = reactive(createFormElementAttributes(this._config.attributes || {}));
  }

  protected buildState() {
    this._state = reactive({
      initialized: false,
      enabled: (typeof this._settings.disabled === 'boolean' ? !this._settings.disabled : !this.parent || this.parent.enabled) as boolean,
      focused: false,
      visible: (typeof this._settings.visible === 'boolean' ? this._settings.visible : !this.parent || this.parent.visible) as boolean,
      loading: typeof this._settings.loading === 'boolean' ? this._settings.loading : (undefined as any as boolean),
      readonly: typeof this._settings.readonly === 'boolean' ? this._settings.readonly : (undefined as any as boolean),
      dirty: false,
      value: null,
      valid: undefined,
      messages: [],
      removable: typeof this._settings.removable === 'boolean' ? this._settings.removable : false,
      needsReRender: false,
      removeFromResult: typeof this._settings.removeFromResult === 'boolean' ? this._settings.removeFromResult : false
    }) as TState;

    this.value = this._settings.defaultValue || this.initialValue;
  }

  protected buildFilters() {
    this._filters = [];

    this._config.filters?.forEach((filterConfig) => {
      this.addFilter(filterConfig);
    });
  }

  protected buildValidators() {
    this._validatorChain = new FormValidatorChain();

    this._config.validators?.forEach((validatorConfig) => {
      this.addValidator(validatorConfig);
    });
  }

  protected buildPlugins() {
    this._plugins = new Map<string, FormElementPlugin>();

    this._config.plugins?.forEach((pluginConfig) => {
      this.addPlugin(pluginConfig);
    });
  }

  protected buildChildren() {
    this._children = reactive([]);

    this._config.children?.forEach((childConfig) => {
      this.addChild(childConfig);
    });
  }

  protected buildChild(componentDefinition: FormElementComponentClassDefinition, config: FormElementConfig<any>, position: number) {
    const childClass = componentDefinition.class;
    const child = new childClass(config, this, position);

    child.build();
    return child;
  }

  public initialize() {
    const debounceTime = this.settings.validation?.onInputDebounce || 0;
    this.createDebounceOnInput(debounceTime);

    this._children.forEach((child) => child.initialize());

    this._state.initialized = true;
    this._plugins.forEach((plugin) => plugin.onInitialize(this));

    if (this.settings.afterInitializedCallback) {
      this.settings.afterInitializedCallback(this);
    }
  }

  public addChild<TChildConfig extends FormElementConfig = FormElementConfig>(config: TChildConfig, position?: number, removable?: boolean) {
    const componentName = config.component || '';
    const componentDefinition = formService.getFormElement(componentName);

    if (componentDefinition) {
      position = position !== undefined ? position : this._children.length;
      const child = this.buildChild(componentDefinition, config, position);

      this._children.splice(position, 0, child);
      child.removable = removable || false;

      const noAutoInitialize = child.settings.autoInitialize === false;
      if (((this.form && this.form.initialized) || !this.form) && !noAutoInitialize) {
        child.initialize();
      }

      this._plugins.forEach((plugin) => plugin.onAddChild(this, child));

      if (this.form && this.form !== this && this.form.initialized) {
        this.form.plugins.forEach((plugin) => plugin.onHierarchyChange(this.form));
      }

      return child;
    }

    return null;
  }

  public getChild = <TElement extends FormElement = FormElement>(name: string) => {
    const child = this._children.find((child) => child.name === name);
    return child ? (child as TElement) : null;
  };

  public removeChildByName = (name: string) => {
    const index = this._children.findIndex((child) => child.name === name);

    if (index >= 0) {
      this.removeChildByIndex(index);
    }
  };

  public removeChildByIndex = (index: number) => {
    if (index < 0 || index >= this._children.length) return;

    const clonedValue = cloneDeep(this.value);

    if (Array.isArray(this.value)) {
      clonedValue.splice(index, 1);
      this.value = clonedValue;
    } else {
      delete clonedValue[this._children[index].name];
      this.value = clonedValue;
    }

    this._children.splice(index, 1);
    this._plugins.forEach((plugin) => plugin.onRemoveChild(this));

    if (Array.isArray(this.value)) {
      this._children.forEach((child, i) => (child.name = `${i}`));
    }

    if (this.form && this.form !== this && this.form.initialized) {
      this.form.plugins.forEach((plugin) => plugin.onHierarchyChange(this.form));
    }
  };

  public removeFromParent = () => {
    if (!this.parent) return;

    if (Array.isArray(this.parent.value)) {
      this.parent.removeChildByIndex(this._positionInParent);
    } else {
      this.parent.removeChildByName(this.name);
    }
  };

  public clearChildren = (removableOnly: boolean) => {
    if (removableOnly) {
      const removableChildren = this._children.filter((child) => child.removable);
      removableChildren.forEach((child) => this.removeChildByName(child.name));
      return;
    }

    this._children.splice(0);
    this.value = this.initialValue;
    this._plugins.forEach((plugin) => plugin.onRemoveChild(this));

    if (this.form && this.form !== this && this.form.initialized) {
      this.form.plugins.forEach((plugin) => plugin.onHierarchyChange(this.form));
    }
  };

  public addFilter = (config: FormFilterConfig) => {
    const instance = formService.getFilterInstance(config.filter);

    if (instance) {
      instance.settings = config.settings || {};
      this._filters.push(instance);
    }
  };

  public getFilter = <TFilter extends FormFilter = FormFilter>(name: string) => {
    const filter = this._filters.find((f) => f.name === name);
    return filter ? (filter as TFilter) : null;
  };

  public addValidator = (config: FormValidatorConfig) => {
    const instance = formService.getValidatorInstance(config.validator);

    if (instance) {
      instance.settings = config.settings || {};
      this._validatorChain.addValidator(instance);
    }
  };

  public getValidator = <TValidator extends FormValidator = FormValidator>(name: string) => {
    const validator = this._validatorChain.getValidator(name);
    return validator ? (validator as TValidator) : null;
  };

  public addPlugin = (config: FormElementPluginConfig) => {
    const instance = formService.getPluginInstance(config.plugin);

    if (instance) {
      this._plugins.set(instance.name, instance);
    }
  };

  public getPlugin = <TPlugin extends FormElementPlugin = FormElementPlugin>(name: string) => {
    const plugin = this._plugins.get(name);
    return plugin ? (plugin as TPlugin) : null;
  };

  public filter() {
    this.value = this.filterValue(this.value);
  }

  public filterWithChildren() {
    this.value = this.filterValue(this.value);
    this._children.forEach((child) => child.filterWithChildren());
  }

  public filterValue(value: any) {
    return this._filters.reduce((prev, current) => current.filter(prev), value);
  }

  public mask() {
    if (this._mask && this.value !== null && this.value !== undefined) {
      this.value = this._mask(this.value.toString());
    }
  }

  public maskValue(value: any) {
    if (this._mask) {
      return this._mask(value);
    }

    return value;
  }

  public validate = (validationOptions?: FormValidationOptions): Promise<FormValidatorResult> => {
    const options = createFormValidationOptions(validationOptions || {});
    const withChildren = options.validateChildren || false;
    const validateDisabled = options.validateDisabled || false;
    const validateInvisible = options.validateInvisible || false;
    const isParentValidation = options.isParentValidation || false;

    const ctx: FormValidatorContext = {
      element: this,
      values: this.form?.value || {}
    };

    if (isParentValidation) {
      return new Promise((resolve) => {
        if (withChildren && this._children.length) {
          const enabledChildren = this._children.filter((child) => (validateInvisible || child.visible) && (validateDisabled || child.enabled));

          Promise.all(enabledChildren.map((element) => element.validate(validationOptions))).then(() => {
            this.validateSelf(ctx, options, resolve);
          });
        } else {
          this.validateSelf(ctx, options, resolve);
        }
      });
    }

    if (this._currentValidation === undefined) {
      this._currentValidation = new Promise((resolve) => {
        if (withChildren && this._children.length) {
          const enabledChildren = this._children.filter((child) => (validateInvisible || child.visible) && (validateDisabled || child.enabled));

          Promise.all(enabledChildren.map((element) => element.validate(validationOptions))).then(() => {
            this.validateSelf(ctx, options, resolve);
          });
        } else {
          this.validateSelf(ctx, options, resolve);
        }
      });
    }

    return this._currentValidation;
  };

  protected validateSelf = (ctx: FormValidatorContext, options: FormValidationOptions, resolve: (value: FormValidatorResult) => void) => {
    const skipMessages = options.skipMessages || false;
    const skipPlugins = options.skipPlugins || false;
    const validateDisabled = options.validateDisabled || false;
    const validateInvisible = options.validateInvisible || false;
    const validationDisabled = !this.initialized || (!validateDisabled && !this.enabled) || (!validateInvisible && !this.visible);

    if (validationDisabled || !this._validatorChain.validatorsCount) {
      this.resetValidator();
      this._state.valid = true;

      this.validateParentIfTriggered().then(() => {
        resolve({
          valid: true,
          messages: []
        } as FormValidatorResult);
        this._currentValidation = undefined;
      });

      return;
    }

    this._validatorChain.validate(this.value, ctx).then((result) => {
      this.resetValidator();
      this._state.valid = result.valid;

      if (!skipPlugins) {
        this._plugins.forEach((plugin) => plugin.onValidate(this, result));
      }

      if (!skipMessages) this._state.messages = result.messages;

      this.validateParentIfTriggered().then(() => {
        resolve(result);
        this._currentValidation = undefined;
      });
    });
  };

  protected validateRelatedElements = async () => {
    if (this._config.relatedElements && this._config.relatedElements.length && this.form) {
      const formForValidation = this.form as Form;

      for (const relatedElement of this._config.relatedElements) {
        const relatedFormElement = formForValidation.getElementInHierarchy(relatedElement);

        if (relatedFormElement) {
          await relatedFormElement.validate();
        }
      }
    }
  };

  protected validateParentIfTriggered = async () => {
    if (this.settings.validation?.validateParent && this.parent) {
      await this.parent.validate({ validateChildren: false, isParentValidation: true });
    }
  };

  public resetValidator = () => {
    this._validatorChain.reset();
    this._state.valid = undefined;
    this._state.messages = [];
  };

  public onInput = async (event: InputEvent) => {
    if (!this.initialized) return;

    if (this.onInputMaskEnabled) {
      this.mask();
    }

    if (this.onInputFilterEnabled) {
      this.filter();
    }

    this.dirty = true;
    this._onInputDebounce(event);
  };

  protected createDebounceOnInput = (debounceTime: number) => {
    this._onInputDebounce = debounce(async (event: InputEvent) => {
      if (this.onInputValidationEnabled) {
        await this.validate();
        await this.validateRelatedElements();
      }

      this._plugins.forEach((plugin) => plugin.onInput(this, this.value, event));

      // input triggers form input events too
      if (this.form && this.form !== this) {
        this.form.plugins.forEach((plugin) => plugin.onInput(this.form, this.form.value, event));
      }
    }, debounceTime);
  };

  public onFocus = async (event: FocusEvent) => {
    if (!this.initialized) return;

    this._state.focused = true;
    this._plugins.forEach((plugin) => plugin.onFocus(this, this._state.focused, event));
  };

  public onBlur = async (event: FocusEvent) => {
    if (!this.initialized) return;

    this._state.focused = false;

    if (this.onBlurMaskEnabled) {
      this.mask();
    }

    if (this.onBlurFilterEnabled) {
      this.filter();
    }

    if (this.onBlurValidationEnabled) {
      await this.validate();
      await this.validateRelatedElements();
    }

    this._plugins.forEach((plugin) => plugin.onFocus(this, this._state.focused, event));

    // blur triggers form blur events too
    if (this.form && this.form !== this) {
      this.form.plugins.forEach((plugin) => plugin.onFocus(this.form, this._state.focused, event));
    }
  };

  public scrollTo = (options?: ScrollIntoViewOptions | undefined) => {
    const htmlElement = document.querySelector(`[name='${this.fullName}']`);
    if (htmlElement) {
      htmlElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start', ...(options ?? {}) });
    }
  };

  public findFirstInvalidChild = () => {
    if (this._children.length) {
      return this._children.find((child) => child.findFirstInvalidChild()) || null;
    }

    return !this.valid ? this : null;
  };

  public get onInputValidationEnabled() {
    if (!this.enabled || !this.visible || this.loading) return false;

    const inSettings = this.settings.validation?.onInput;
    if (inSettings === false) return false;
    if (inSettings === true || (inSettings === undefined && !this.parent)) return true;

    return !!this.parent?.onInputValidationEnabled;
  }

  public get onBlurValidationEnabled() {
    if (!this.enabled || !this.visible || this.loading) return false;

    const inSettings = this.settings.validation?.onBlur;
    if (inSettings === false) return false;
    if (inSettings === true || (inSettings === undefined && !this.parent)) return true;

    return !!this.parent?.onBlurValidationEnabled;
  }

  public get onInputFilterEnabled() {
    const inSettings = this.settings.filter?.onInput;

    if (inSettings === false) return false;
    if (inSettings === true || (inSettings === undefined && !this.parent)) return true;

    return !!this.parent?.onInputFilterEnabled;
  }

  public get onBlurFilterEnabled() {
    const inSettings = this.settings.filter?.onBlur;

    if (inSettings === false) return false;
    if (inSettings === true || (inSettings === undefined && !this.parent)) return true;

    return !!this.parent?.onBlurFilterEnabled;
  }

  public get onValueChangeFilterEnabled() {
    const inSettings = this.settings.filter?.onValueChange;

    if (inSettings === false) return false;
    if (inSettings === true || (inSettings === undefined && !this.parent)) return true;

    return !!this.parent?.onValueChangeFilterEnabled;
  }

  public get onInputMaskEnabled() {
    if (this.settings.mask === undefined || !('config' in this.settings.mask) || !('onInput' in this.settings.mask)) return true;
    return this.settings.mask.onInput !== false;
  }

  public get onBlurMaskEnabled() {
    if (this.settings.mask === undefined || !('config' in this.settings.mask) || !('onBlur' in this.settings.mask)) return false;

    return this.settings.mask.onBlur !== false;
  }

  public get onValueChangeMaskEnabled() {
    if (this.settings.mask === undefined || !('config' in this.settings.mask) || !('onValueChange' in this.settings.mask)) return false;

    return this.settings.mask.onValueChange !== false;
  }

  public getParentDependentAttribute = (name: string, defaultValue: any = undefined) => {
    return this._attributes[name] || this._parent?.getParentDependentAttribute(name, defaultValue) || defaultValue;
  };

  public getParentDependentSetting = (name: string, defaultValue: any = undefined) => {
    return this._settings[name] || this._parent?.getParentDependentSetting(name, defaultValue) || defaultValue;
  };

  protected get initialValue(): any {
    return null;
  }

  public get config() {
    return this._config;
  }

  public get component() {
    return this._component;
  }

  public get componentSettings() {
    return this._componentSettings;
  }

  public get initialized() {
    return this._state.initialized;
  }

  public get name() {
    return this._name;
  }

  public set name(name) {
    this._name = name;
  }

  public get fullName() {
    let name = this.name;

    if (this._parent) {
      name = `${this._parent.fullName}.${name}`;
    }

    return name.replace(/[.]+/, '.');
  }

  public get settings() {
    return this._settings;
  }

  public get attributes() {
    return this._attributes;
  }

  public get form() {
    if (this.parent) {
      return this.parent.form;
    }

    return this instanceof Form ? this : null;
  }

  public get parent() {
    return this._parent;
  }

  public get children() {
    return this._children;
  }

  public get allChildren() {
    const result: FormElement<any>[] = [];

    this.children.forEach((child) => {
      result.push(child);
      result.push(...child.allChildren);
    });

    return result;
  }

  public get value() {
    const parent = this.parent instanceof FormStepElement ? this.form : this.parent;

    if (!this.removeFromResult && parent) {
      if (!(this.name in parent.value)) parent.value[this.name] = this.initialValue;
      return parent.value[this.name];
    }

    return this._state.value;
  }

  public set value(value) {
    let cleaned = toRaw(customDeepClone(value));

    if (this.onValueChangeFilterEnabled) {
      cleaned = toRaw(this.filterValue(cleaned));
    }

    if (this.initialized && this.enabled && this.beforeValueChange !== undefined) {
      const canChange = this.beforeValueChange(this, cleaned);
      if (isPromise(canChange)) {
        (canChange as Promise<boolean>).then((result) => {
          if (result) this.setValue(cleaned);
          else this.needsReRender = true;
        });
      } else {
        if (canChange === true) this.setValue(cleaned);
      }

      return;
    }

    this.setValue(cleaned);
  }

  protected setValue = (cleaned: any) => {
    const parent: FormElement = this.parent instanceof FormStepElement ? this.form : this.parent;

    if (!this.removeFromResult && parent && parent.value !== null) {
      if (this.name in parent.value && toRaw(parent.value[this.name]) == cleaned) {
        if (this.afterValueChange !== undefined) {
          this.afterValueChange(this, cleaned);
        }

        return;
      }

      if (parent.config.component && parent.config.component === 'formArray') {
        const position = this._positionInParent;
        const newFormArrayValue = [...parent.value];
        newFormArrayValue[position] = cleaned;
        parent.value = [...newFormArrayValue];
      } else {
        parent.value = { ...parent.value, [this.name]: cleaned };
      }

      this._plugins.forEach((plugin) => plugin.onValueChange(this, cleaned));

      if (this.afterValueChange !== undefined) {
        this.afterValueChange(this, cleaned);
      }

      return;
    }

    if (toRaw(this._state.value) == cleaned) return;
    this._state.value = cleaned;
    this._plugins.forEach((plugin) => plugin.onValueChange(this, cleaned));

    if (this.afterValueChange !== undefined) {
      this.afterValueChange(this, cleaned);
    }
  };

  public get validatorChain() {
    return this._validatorChain;
  }

  public get currentValidation() {
    return this._currentValidation;
  }

  public set currentValidation(currentValidation) {
    this._currentValidation = currentValidation;
  }

  public get valid() {
    return this._state.valid;
  }

  public set valid(valid) {
    this._state.valid = valid;
  }

  public get validWithChildren() {
    return this._children.reduce((prev, next) => prev && (!next.enabled || !next.visible || next.validWithChildren), this._state.valid !== false);
  }

  public set validWithChildren(valid) {
    this._state.valid = valid;
    if (valid) this.resetValidator();

    this.children.forEach((child) => {
      child.validWithChildren = valid;
    });
  }

  public get messages() {
    return this._state.messages;
  }

  public set messages(messages) {
    this._state.messages = messages;
  }

  public get message() {
    const messages = this.messages;
    const errorMessages = messages.filter((m) => m.type === 'error');
    const warningMessages = messages.filter((m) => m.type === 'warning');
    const infoMessages = messages.filter((m) => m.type === 'info');
    const hint = (this._attributes.hint as string) || null;
    const persistentHint = (this._attributes.persistentHint as boolean) || false;

    let msg: FormValidatorMessage | null = null;

    if (errorMessages.length) msg = errorMessages[0];
    else if (warningMessages.length) msg = warningMessages[0];
    else if (infoMessages.length) msg = infoMessages[0];
    else if (hint && hint.length && (persistentHint || this.focused))
      msg = { message: hint, type: 'hint', validator: '', settings: { needTranslate: this.settings.needHintTranslate || false } };
    else if (messages.length) msg = messages[0];

    return msg;
  }

  public get messagesByFields() {
    let result = {};

    result[this.fullName] = toRaw(toValue(this.messages));
    this.children.forEach((child) => (result = { ...result, ...toRaw(child.messagesByFields) }));

    return result;
  }

  public get validationDataByFields() {
    let result = {};

    result[this.fullName] = {
      valid: this.validWithChildren,
      messages: toRaw(toValue(this.messages))
    };

    this.children.forEach((child) => (result = { ...result, ...toRaw(child.validationDataByFields) }));

    return result;
  }

  public get enabled() {
    const disabledFunc = typeof this._settings.disabled === 'function' ? this._settings.disabled : null;

    if (disabledFunc) {
      this._state.enabled = !disabledFunc(this);
    }

    return this._state.enabled && (!this.parent || this.parent?.enabled);
  }

  public set enabled(enabled) {
    this._state.enabled = enabled;
    this._plugins.forEach((plugin) => plugin.onEnable(this, this._state.enabled));
  }

  public get visible() {
    const visibleFunc = typeof this._settings.visible === 'function' ? this._settings.visible : null;

    if (visibleFunc) {
      this._state.visible = visibleFunc(this);
    }

    return this._state.visible && (!this.parent || this.parent?.visible);
  }

  public set visible(visible) {
    this._state.visible = visible;
    this._plugins.forEach((plugin) => plugin.onVisible(this, this._state.visible));
  }

  public get readonly() {
    const readonlyFunc = typeof this._settings.readonly === 'function' ? this._settings.readonly : null;

    if (readonlyFunc) {
      this._state.readonly = readonlyFunc(this);
    }

    return this._state.readonly || (this.parent && this.parent.readonly);
  }

  public set readonly(readonly) {
    this._state.readonly = readonly;
  }

  public get focused() {
    return this._state.focused;
  }

  public get loading() {
    const loadingFunc = typeof this._settings.loading === 'function' ? this._settings.loading : null;

    if (loadingFunc) {
      this._state.loading = loadingFunc(this);
    }

    return this._state.loading || (this.parent && this.parent.loading);
  }

  public set loading(loading) {
    this._state.loading = loading;
  }

  public get dirty() {
    return this._state.dirty;
  }

  public set dirty(dirty) {
    this._state.dirty = dirty;
  }

  public get dirtyWithChildren() {
    return this._children.reduce((prev, next) => prev || (next.enabled && next.visible && next.dirtyWithChildren), this._state.dirty);
  }

  public set dirtyWithChildren(dirty: boolean) {
    this._state.dirty = dirty;
    this.children.forEach((child) => (child.dirtyWithChildren = dirty));
  }

  public get plugins() {
    return this._plugins;
  }

  public get removable() {
    const removableFunc = typeof this._settings.removable === 'function' ? this._settings.removable : null;

    if (removableFunc) {
      this._state.removable = removableFunc(this);
    }

    return this._state.removable;
  }

  public set removable(removable) {
    this._state.removable = removable;
  }

  public get beforeValueChange() {
    return this._beforeValueChange;
  }

  public set beforeValueChange(fn) {
    this._beforeValueChange = fn;
  }

  public get afterValueChange() {
    return this._afterValueChange;
  }

  public set afterValueChange(fn) {
    this._afterValueChange = fn;
  }

  public get needsReRender() {
    return this._state.needsReRender;
  }

  public set needsReRender(value) {
    this._state.needsReRender = value;
  }

  public get removeFromResult() {
    return this._state.removeFromResult;
  }

  public set removeFromResult(removeFromResult) {
    this._state.removeFromResult = removeFromResult;
  }
}

export class SelectableFormElement<TConfig extends SelectableFormElementConfig = SelectableFormElementConfig> extends FormElement<TConfig> {
  protected _items!: any[];

  public override build(config?: TConfig) {
    super.build(config);
    this.buildItems();
  }

  protected override buildConfig(config?: TConfig) {
    this._config = createMultiFormElementConfig(config || this._config) as TConfig;
  }

  protected buildItems() {
    this._items = reactive([]);

    (this._settings as SelectableFormElementSettings).items?.forEach((item) => {
      this._items.push(item);
    });
  }

  protected override get initialValue(): any {
    const multiple = (this._attributes as SelectableFormElementAttributes).multiple || false;
    return multiple ? [] : null;
  }

  public get items() {
    return this._items;
  }

  public set items(items) {
    this._items.splice(0);
    this._items.push(...items);
  }
}

export class VuetifyFormElement<TConfig extends FormElementConfig = FormElementConfig> extends FormElement<TConfig> {
  protected override buildComponent() {
    super.buildComponent();

    if (this._componentSettings) {
      const vuetifySettings = this._componentSettings as VuetifyFieldComponentSettings;
      vuetifySettings.vuetifyComponent = markRaw(vuetifySettings.vuetifyComponent);
    }
  }
}

export class VuetifySelectableFormElement<
  TConfig extends SelectableFormElementConfig = SelectableFormElementConfig
> extends SelectableFormElement<TConfig> {
  protected override buildComponent() {
    super.buildComponent();

    if (this._componentSettings) {
      const vuetifySettings = this._componentSettings as VuetifyFieldComponentSettings;
      vuetifySettings.vuetifyComponent = markRaw(vuetifySettings.vuetifyComponent);
    }
  }
}

export class FormGroupElement<TConfig extends FormGroupConfig = FormGroupConfig> extends FormElement<TConfig> {
  protected _sheetAttributes!: FormGroupSheetAttributes;

  protected override buildConfig(config?: TConfig) {
    this._config = createFormGroupConfig(config || this._config) as TConfig;
  }

  protected override buildAttributes() {
    super.buildAttributes();
    this._attributes.persistentHint = true;
    this._sheetAttributes = reactive(createFormGroupSheetAttributes(this._config.sheetAttributes || {}));
  }

  protected override get initialValue(): any {
    return {};
  }

  public get sheetAttributes() {
    return this._sheetAttributes;
  }
}

export class FormArrayElement extends FormGroupElement<FormArrayConfig> {
  protected _isFormArray = true;

  protected override buildChild(
    componentDefinition: FormElementComponentClassDefinition,
    config: FormElementConfig<any>,
    position: number
  ): FormElement<any> {
    const childClass = componentDefinition.class;

    config.name = `${position}`;
    const child = new childClass(config, this, position);
    child.build();

    return child;
  }

  protected override get initialValue(): any {
    return [];
  }

  public get isFormArray(): boolean {
    return this._isFormArray;
  }

  public get addIndexToChildLabel(): boolean {
    return this._config.settings?.actions?.add?.addIndexToChildLabel || false;
  }
}

export class FormStepElement<TConfig extends FormStepConfig = FormStepConfig> extends FormGroupElement<TConfig> {
  protected _step = 1;

  protected override buildState() {
    super.buildState();
    this._step = this._positionInParent + 1;
  }

  public get fullName() {
    let name = '';

    if (this._parent) {
      name = `${this._parent.fullName}.${name}`;
    }

    return name.replace(/[.]+/, '.');
  }

  public get stepRule() {
    return [() => this.validWithChildren !== false];
  }

  public get step() {
    return this._step;
  }
}

export class CheckboxElement extends VuetifyFormElement<CheckboxConfig> {
  protected override get initialValue(): any {
    return (this._attributes as CheckboxAttributes).falseValue || false;
  }
}
