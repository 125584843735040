import { FormValidator, type FormValidatorConfig, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';

const COMPANY_NUMBER_REGEXP = /^\d{2}-\d{2}-\d{6}$/;

export type CompanyNumberValidatorSettings = FormValidatorSettings;
export type CompanyNumberValidatorConfig = FormValidatorConfig<CompanyNumberValidatorSettings>;

export class CompanyNumberValidator extends FormValidator<CompanyNumberValidatorSettings> {
  constructor() {
    super();
    this._name = 'company-number';
    this._settings = {
      invalidMessage: 'form.validator.companyNumber.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any) {
    if (isEmpty(value)) {
      return;
    }

    this._valid = COMPANY_NUMBER_REGEXP.test(value);

    if (typeof value === 'boolean') this._valid &&= value;

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage);
    }
  }
}
