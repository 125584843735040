import { toRaw } from 'vue';
import { FormValidator, type FormValidatorContext, type FormValidatorConfig, type FormValidatorSettings } from '../models';

export interface SameAsValidatorSettings extends FormValidatorSettings {
  otherFieldName: string;
}

export type SameAsValidatorConfig = FormValidatorConfig<SameAsValidatorSettings>;

export class SameAsValidator extends FormValidator<SameAsValidatorSettings> {
  constructor() {
    super();
    this._name = 'sameAs';
    this._settings = {
      invalidMessage: 'form.validator.sameAs.invalidMessage',
      needTranslate: true,
      otherFieldName: ''
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    const values = toRaw(context.values);
    const otherValue = values[this.settings.otherFieldName];

    this._valid = value === otherValue;

    if (!this._valid) {
      this.addMessage(this.settings.invalidMessage);
    }
  }
}
