<script lang="ts" setup>
import type { PropType } from 'vue';
import { useSelectableFormElement } from '../composables';
import { SelectableFormElement } from '../models';
import { AxialFieldMessage } from './';
import { ref, watch } from 'vue';

const props = defineProps({
  element: {
    type: Object as PropType<SelectableFormElement>,
    required: true
  },
  componentSettings: {
    type: Object as PropType<any>,
    required: false
  }
});

const {
  attributes,
  enabled,
  readonly,
  loading,
  value,
  fullName,
  messagesAsString,
  hasErrorMessages,
  hasWarningMessages,
  hasInfoMessages,
  onInput,
  onFocus,
  onBlur,
  labelComponent,
  prependComponent,
  appendComponent,
  items,
  label,
  hint,
  color,
  bgColor,
  density,
  variant,
  isColumn,
  isInline,
  itemLabelComponent,
  itemLabel,
  itemValue,
  needsReRender
} = useSelectableFormElement(props.element);

const groupKey = ref(0);

watch(needsReRender, () => {
  if (needsReRender.value) {
    groupKey.value++;
    needsReRender.value = false;
  }
});
</script>

<template>
  <v-radio-group
    v-bind="attributes"
    v-model="value"
    :key="groupKey"
    :label="label"
    :hint="hint"
    :disabled="!enabled"
    :readonly="readonly"
    :loading="loading"
    :density="density"
    :column="isColumn"
    :inline="isInline"
    :messages="messagesAsString"
    :class="{
      'v-input--error': hasErrorMessages,
      'v-input--warning': hasWarningMessages,
      'v-input--info': hasInfoMessages
    }"
    @focus="onFocus"
    @blur="onBlur"
    @input="onInput">
    <v-radio
      class="pt-2"
      v-for="(item, i) in items"
      :key="i"
      :name="fullName"
      :label="itemLabel(item)"
      :value="itemValue(item)"
      :color="color"
      :bg-color="bgColor"
      :variant="variant"
      :density="density"
      :disabled="item.enabled === false || !enabled">
      <!-- TODO_FORM: item disabled is ok? -->
      <template
        v-if="itemLabelComponent"
        #label="{ label, props }">
        <component
          :is="itemLabelComponent"
          :label="label"
          :props="props"
          :element="element"
          :item="item"
          :item-index="i" />
      </template>
    </v-radio>

    <template #message>
      <axial-field-message :element="element" />
    </template>

    <template
      v-if="prependComponent"
      #prepend>
      <component
        :is="prependComponent"
        :element="element" />
    </template>
    <template
      v-if="appendComponent"
      #append>
      <component
        :is="appendComponent"
        :element="element" />
    </template>
    <template
      v-if="labelComponent"
      #label="{ label, props }">
      <component
        class="radio-group-title"
        :is="labelComponent"
        :label="label"
        :props="props"
        :element="element" />
    </template>
  </v-radio-group>
</template>
