import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import type { ApiPlugin } from '../';
import { ApiResponseStatus } from '../';

export class DefaultResponsePlugin implements ApiPlugin {
  initialize(instance: AxiosInstance): void {
    instance.interceptors.response.use(
      (response) => {
        return DefaultResponsePlugin.getFulfilled(response);
      },
      (error: AxiosError) => {
        return DefaultResponsePlugin.getRejected(error);
      }
    );
  }

  static getFulfilled(response: AxiosResponse) {
    return {
      originalResponse: response,
      status: (response?.status as unknown as ApiResponseStatus) || ApiResponseStatus.SUCCESS,
      message: response?.statusText || null,
      code: response.status || 0,
      data: response.data || null
    } as any as AxiosResponse;
  }

  static getRejected(error: AxiosError) {
    const response = error.response;

    return Promise.reject({
      originalResponse: response,
      originalError: error,
      status: (response?.status as unknown as ApiResponseStatus) || ApiResponseStatus.ERROR,
      message: response?.statusText || error.message || null,
      code: response?.status || 0,
      data: response || null
    });
  }
}
