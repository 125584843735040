import { FormValidator, type FormValidatorConfig, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';

export interface UniqueInStringListValidatorSettings extends FormValidatorSettings {
  stringList: string[];
}
export type UniqueInStringListValidatorConfig = FormValidatorConfig<UniqueInStringListValidatorSettings>;

export class UniqueInStringListValidator extends FormValidator<UniqueInStringListValidatorSettings> {
  constructor() {
    super();
    this._name = 'unique-in-string-list';
    this._settings = {
      stringList: [],
      invalidMessage: 'form.validator.uniqueInStringList.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any) {
    if (isEmpty(value)) {
      return;
    }

    this._valid = !this.settings.stringList.includes(value);

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage);
    }
  }
}
